import { TextField } from '@material-ui/core';
import { LAST_MESSAGE_RANGE } from 'Common/constant';
import React from 'react';

export default function RangeRadioField({
  type,
  range,
  fieldName,
  onChange,
  title,
}) {
  const handleChange = (type, range) => {
    onChange(fieldName, { type, range });
  };

  return (
    <>
      {title && (
        <p
          style={{
            position: 'relative',
            top: '0.25rem',
            marginBottom: '0.125rem',
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '1rem',
          }}
        >
          {title}
        </p>
      )}
      <div style={{ display: 'flex', gap: '8px', marginBottom: '8px' }}>
        <div
          style={{
            display: 'flex',
            flexShrink: 0,
            marginBottom: '8px',
            alignItems: 'end',
          }}
        >
          <label className="radio-group" style={{ marginBottom: 0 }}>
            <input
              name={fieldName}
              type="radio"
              value={LAST_MESSAGE_RANGE.ALL}
              checked={type === LAST_MESSAGE_RANGE.ALL}
              onChange={(e) => handleChange(LAST_MESSAGE_RANGE.ALL, range)}
            />
            <span
              style={
                type !== LAST_MESSAGE_RANGE.ALL
                  ? { color: 'rgba(0, 0, 0, 0.38)' }
                  : {}
              }
            >
              全て
            </span>
          </label>
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          <label className="radio-group" style={{ width: 'auto' }}>
            <input
              name={fieldName}
              type="radio"
              value={LAST_MESSAGE_RANGE.WITHIN_RANGE}
              checked={type === LAST_MESSAGE_RANGE.WITHIN_RANGE}
              onChange={(e) =>
                handleChange(LAST_MESSAGE_RANGE.WITHIN_RANGE, range)
              }
            />
            <span />
          </label>
          <TextField
            label="以内送信有"
            type="tel"
            disabled={type !== LAST_MESSAGE_RANGE.WITHIN_RANGE}
            value={type === LAST_MESSAGE_RANGE.WITHIN_RANGE ? range : ''}
            onChange={(e) => handleChange(type, e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          <label className="radio-group" style={{ width: 'auto' }}>
            <input
              name={fieldName}
              type="radio"
              value={LAST_MESSAGE_RANGE.OUT_OF_RANGE}
              checked={`${type}` === `${LAST_MESSAGE_RANGE.OUT_OF_RANGE}`}
              onChange={() =>
                handleChange(LAST_MESSAGE_RANGE.OUT_OF_RANGE, range)
              }
              onClick={() =>
                handleChange(LAST_MESSAGE_RANGE.OUT_OF_RANGE, range)
              }
            />
            <span />
          </label>
          <TextField
            label="以内送信無"
            type="tel"
            disabled={type !== LAST_MESSAGE_RANGE.OUT_OF_RANGE}
            value={type === LAST_MESSAGE_RANGE.OUT_OF_RANGE ? range : ''}
            onChange={(e) => handleChange(type, e.target.value)}
          />
        </div>
      </div>
    </>
  );
}
