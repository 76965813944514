import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import orderBy from 'lodash/orderBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Label, Row } from 'reactstrap';
import {
  DISPLAY_CMS_UI_TYPE,
  GENDER,
  NOTIFY_FILED_TYPES,
  ON_OFF_TYPE,
} from '../../../Common/constant';
import { USER_DETAIL_MESSAGES } from '../../../Common/constant_text';
import LoadingBar from '../../../Components/LoadingBar';
import {
  CODE_MAP_DEFINED_API,
  LINK_MAP_DEFINED_API,
  PROFILE_TYPE,
} from '../../../Config/listConstants';
import { loadEnvInfo } from '../../../Redux/Actions/envInfo';
import { loadUser } from '../../../Redux/Actions/user';
import Util from '../../../Util/Util';
import {
  accessAndPassDataIframe,
  convertCharaStatusText,
  convertEmailAddressMainStatusText,
  convertEnvInfo,
  convertInspectionStatusText,
  convertMemberCategoryTypeText,
  convertNeedOtpText,
  convertRegisterStatusText,
  convertRegisterTypeText,
  convertSettingNotifyText,
  convertStandByText,
} from '../../../Util/utils';
import BlockModal from './BlockModal';
import BlogLogModal from './BlogLogModal';
import ChatModel from './ChatModel';
import FavoriteJob from './FavoriteJob';
import FavoriteModel from './FavoriteModel';
import FootprintModel from './FootPrintModel';
import ImageList from './ImageList';
import JobEntry from './JobEntry';
import LikeModel from './LikeModel';
import PaymentLogModel from './PaymentLogModel';
import PaymentModal from './PaymentModal';
import PointGetModal from './PointGetModal';
import PointUsageModal from './PointUsageModal';
import RemoveUserModal from './RemoveUserModal';
import SalatyLogModal from './SalaryLogModal';
import SendEmailModal from './SendEmailModal';
import SendNotifyModal from './SendNotiModal';
import SendSmsModal from './SendSmsModal';
import './styles.scss';
import TwilioHistoryModal from './TwilioHistoryModal';
import UpdateSelfIntroductionPage from './UpdateSelfIntroductionPage';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};
const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

function Index({ location }) {
  const params = useParams();
  const userId = params.userId;
  const [chatVisible, setChatVisible] = useState(false);
  const [likeVisible, setLikeVisible] = useState(false);
  const [pointVisible, setPointVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [smsVisible, setSmsVisible] = useState(false);
  const [updateProfilePageVisible, setUpdateProfilePageVisible] =
    useState(false);
  const [emailVisible, setEmailVisible] = useState(false);
  const [notiVisible, setNotiVisible] = useState(false);
  const [addPointVisible, setAddPointVisible] = useState(false);
  const [favoriteVisible, setFavoriteVisible] = useState(false);
  const [footprintVisible, setFootprintVisible] = useState(false);
  const [salaryLogVisible, setSalaryLogVisible] = useState(false);
  const [blogLogVisible, setBlogLogVisible] = useState(false);
  const [blockVisible, setBlockVisible] = useState(false);
  const [pointLogVisible, setPointLogVisible] = useState(false);
  const [pointUsageVisible, setPointUsageVisible] = useState(false);
  const [twilioHistoryVisible, setTwilioHistoryVisible] = useState(false);
  const [moveToArchive, setMoveToArchive] = useState(false);
  const [myProfVisible, setMyProfVisible] = useState(false);
  const [favoriteJobVisible, setFavoriteJobVisible] = useState(false);
  const [jobEntryVisible, setJobEntryVisible] = useState(false);
  const [colorLabel, setColorLabel] = useState('');
  const rankOptions = useSelector((state) => state.nameRank.data);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(
      convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1
        ?.content,
    );
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(loadEnvInfo());
  }, []);

  const reload = () => {
    dispatch(loadUser(userId));
  };

  const history = useHistory();
  const redirectPageEditUser = (id) => {
    history.push(`/user/${id}/edit`);
  };

  const loading = useSelector((state) => state.user.loading);
  const user = useSelector((state) => state.user.data);
  const resource = useSelector((state) => state.resource.data);
  const envUICms = useSelector((state) => state.envUICms.data);
  const admin = useSelector((state) => state.admin.data);

  const chatLogButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_CHAT_LOG,
  );
  const likeButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_LIKE,
  );
  const paymentLogButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_PAYMENT_LOG,
  );
  const sendNotiButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_NOTIFY_SEND,
  );
  const sendEmailButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_EMAIL_SEND,
  );
  const sendSmsButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_SMS_SEND,
  );
  const updateProfilePageButton = envUICms.find(
    (item) =>
      item.link === LINK_MAP_DEFINED_API.USER_SEARCH_UPDATE_PROFILE_PAGE_MEMBER,
  );
  const editButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_EDIT,
  );
  const deleteButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_DELETE,
  );
  const addPointButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_ADD_POINT,
  );
  const favoriteButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_FAVORITE,
  );
  const footprintButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_FOOTPRINT,
  );
  const salaryLogButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_SALARY_LOG,
  );
  const blogLogButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_BLOG_LOG,
  );
  const blockButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_BLOCK,
  );
  const pointLogButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_POINT_LOG,
  );
  const pointUsageButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_POINT_USAGE,
  );
  const moveToArchiveButton = envUICms.find(
    (item) =>
      item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_MOVE_TO_ARCHIVE,
  );
  const twilioHistoryButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_TWILIO_LOG,
  );
  const favoritejobButton = envUICms.find(
    (item) =>
      item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_FAVORITE_JOB,
  );
  const jobEntryButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DETAIL_JOB_ENTRY,
  );
  // const envInfoTag = envInfo.filter(item => 'site_name,contact_mail_address,url_lp,contact_phone_number,url_contact'.includes(item.code));
  const envInfoTag = envInfo.filter((item) =>
    [
      CODE_MAP_DEFINED_API.SITE_NAME,
      CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS,
      CODE_MAP_DEFINED_API.URL_LP,
      CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER,
      CODE_MAP_DEFINED_API.URL_CONTACT,
    ].includes(item.code),
  );

  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleClose = () => {
    setChatVisible(false);
    setLikeVisible(false);
    setPointVisible(false);
    setConfirmVisible(false);
    setSmsVisible(false);
    setUpdateProfilePageVisible(false);
    setEmailVisible(false);
    setNotiVisible(false);
    setAddPointVisible(false);
    setFavoriteVisible(false);
    setFootprintVisible(false);
    setSalaryLogVisible(false);
    setBlogLogVisible(false);
    setBlockVisible(false);
    setPointLogVisible(false);
    setPointUsageVisible(false);
    setTwilioHistoryVisible(false);
    setFavoriteJobVisible(false);
    setJobEntryVisible(false);
  };
  const handleClickChat = () => {
    setChatVisible(true);
  };
  const handleClickLike = () => {
    setLikeVisible(true);
  };
  const handleClickPoint = () => {
    setPointVisible(true);
  };
  const handleClickSms = () => {
    setSmsVisible(true);
  };
  const handleClickSmsDefault = () => {
    setUpdateProfilePageVisible(true);
  };
  const handleClickEmail = () => {
    setEmailVisible(true);
  };
  const handleClickNoti = () => {
    setNotiVisible(true);
  };
  const handleClickAddPoint = () => {
    setAddPointVisible(true);
  };
  const handleClickFavorite = () => {
    setFavoriteVisible(true);
  };
  const handleClickFootprint = () => {
    setFootprintVisible(true);
  };
  const handleClickSalaryLog = () => {
    setSalaryLogVisible(true);
  };
  const handleClickBlogLog = () => {
    setBlogLogVisible(true);
  };
  const handleClickBlock = () => {
    setBlockVisible(true);
  };
  const handleRemoveUser = () => {
    setMoveToArchive(false);
    setConfirmVisible(true);
  };
  const handleMoveToArchiveUser = () => {
    setMoveToArchive(true);
    setConfirmVisible(true);
  };
  const handleClickPointLog = () => {
    setPointLogVisible(true);
  };
  const handleClickPointUsage = () => {
    setPointUsageVisible(true);
  };
  const handleClickTwilioHistory = () => {
    setTwilioHistoryVisible(true);
  };
  const handleClickFavoriteJob = () => {
    setFavoriteJobVisible(true);
  };
  const handleClickJobEntry = () => {
    setJobEntryVisible(true);
  };

  // Profile
  const showResource = () => {
    let userGender = Number(user.charaStatus)
      ? user.gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : user.gender === GENDER.FEMALE
        ? 'female'
        : 'male';

    return resource.items
      .filter(
        (item) =>
          item.value === PROFILE_TYPE.ROOT && item.gender === userGender,
      )
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        return {
          label: resourceItem.label,
          name: resourceItem?.profile,
          value: findAttributes(resourceItem.label, userGender)?.label,
        };
      })
      .map((item, key) => (
        <>
          <Label style={style} xs={5}>
            <p style={{ paddingLeft: 3, color: colorLabel }}>{item.label}</p>
            <hr style={stylehrleft} />
          </Label>
          <Label style={style} xs={7}>
            <p style={{ paddingLeft: 3 }}>{item?.value}</p>
            <hr style={stylehrright} />
          </Label>
        </>
      ));
  };

  const findAttributes = (resourceName, userGender) => {
    const parentName = resource.items.find(
      (x) => x.gender === userGender && x.label === resourceName,
    );

    return resource?.items.find((x) => {
      return (
        user?.attributes &&
        user?.attributes[parentName?.profile] &&
        x.gender === userGender &&
        x?.profile === parentName?.profile &&
        +x.value === +user?.attributes[parentName?.profile]
      );
    });
  };

  const handleMyProfile = () => {
    setMyProfVisible(true);
  };

  const copyToClipboard = (text) => () => {
    function fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        toast.success(USER_DETAIL_MESSAGES.COPY_ID_SUCCESS);
      } catch (err) {
        toast.error(USER_DETAIL_MESSAGES.COPY_ID_FAILED);
      }

      document.body.removeChild(textArea);
    }

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    navigator.clipboard.writeText(text).then(
      function () {
        toast.success(USER_DETAIL_MESSAGES.COPY_ID_SUCCESS);
      },
      function (err) {
        toast.error(USER_DETAIL_MESSAGES.COPY_ID_FAILED);
      },
    );
  };

  const accessAndPassData = () => {
    accessAndPassDataIframe('iframeId', 'PREVIEW_DATA', {
      classListHidden: ['.iframe-trigger-preview', '.iframe-pre-hidden-btn'],
    });
  };

  const userDetailsFieldTitleText = {
    REGISTER_DATE: '登録日',
    LAST_LOGIN: '最終ログイン',
    FIST_RECEIVED_MESSAGE: '初回メッセージ受信',
    LAST_RECEIVED_MESSAGE: '最終メッセージ受信',
    FIRST_SENT_MESSAGE: '初回メッセージ送信',
    LAST_SENT_MESSAGE: '最終メッセージ送信',
    FIRST_PAID_DATE: '初回入金日',
    LAST_PAID_DATE: '最終入金日',
  };

  const timestampDataArray = useMemo(() => {
    if (!user) return [];
    return [
      {
        title: userDetailsFieldTitleText.REGISTER_DATE,
        timestamp: user.registerDate,
      },
      {
        title: userDetailsFieldTitleText.LAST_LOGIN,
        timestamp: user.lastlogin,
      },
      {
        title: userDetailsFieldTitleText.FIST_RECEIVED_MESSAGE,
        timestamp: user.firstMessageReceivedDate,
      },
      {
        title: userDetailsFieldTitleText.LAST_RECEIVED_MESSAGE,
        timestamp: user.lastMessageReceivedDate,
      },
      {
        title: userDetailsFieldTitleText.FIRST_SENT_MESSAGE,
        timestamp: user.firstMessageSendingDate,
      },
      {
        title: userDetailsFieldTitleText.LAST_SENT_MESSAGE,
        timestamp: user.lastMessageSendingDate,
      },
      {
        title: userDetailsFieldTitleText.FIRST_PAID_DATE,
        timestamp: user.firstPayingDate,
      },
      {
        title: userDetailsFieldTitleText.LAST_PAID_DATE,
        timestamp: user.lastPayingDate,
      },
    ];
  }, [user]);
  const renderTimestampField = useCallback(
    (array) => {
      return array.map((e) => (
        <React.Fragment>
          <Label style={style} xs={5}>
            <p style={{ paddingLeft: 3, color: colorLabel }}>{e.title}</p>
            <hr style={stylehrleft} />
          </Label>
          <Label style={style} xs={7}>
            {Util.timestampToDate(e.timestamp, 'YYYY/MM/DD HH:mm')}
            <hr style={stylehrright} />
          </Label>
        </React.Fragment>
      ));
    },
    [colorLabel],
  );

  return (
    <>
      <Helmet>
        <title>ユーザー詳細</title>
      </Helmet>
      <div>
        <div>
          {chatVisible && (
            <ChatModel
              visible={chatVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
            />
          )}
          {likeVisible && (
            <LikeModel
              visible={likeVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
            />
          )}
          {pointVisible && (
            <PaymentLogModel
              visible={pointVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
              user={user}
            />
          )}
          {confirmVisible && (
            <RemoveUserModal
              visible={confirmVisible}
              onClose={handleClose}
              userId={userId}
              moveToArchive={moveToArchive}
              labelDb={labelDb}
            />
          )}

          {smsVisible && (
            <SendSmsModal
              visible={smsVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
              depositpoint={Util.separator(user?.depositpoint)}
              phoneNumber={user.phoneNumber}
              password={user.password}
              envInfoTag={envInfoTag}
            />
          )}

          {updateProfilePageVisible && (
            <UpdateSelfIntroductionPage
              visible={updateProfilePageVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user?.nickName}
              avatar={user?.avatar}
              phoneNumber={user?.phoneNumber}
              needOtp={user?.needOtp}
              password={user?.password}
              depositPoint={Util.separator(user?.depositpoint)}
              envInfoTag={envInfoTag}
              labelDb={labelDb}
              htmlProfilePageMember={user?.profilePageMember?.html}
            />
          )}

          {emailVisible && (
            <SendEmailModal
              visible={emailVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              phoneNumber={user.phoneNumber}
              password={user.password}
              needOtp={user.needOtp}
              email={user.emailAddressMain}
              depositpoint={Util.separator(user.depositpoint)}
              envInfoTag={envInfoTag}
              reload={reload}
              labelDb={labelDb}
            />
          )}

          {notiVisible && (
            <SendNotifyModal
              visible={notiVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {addPointVisible && (
            <PaymentModal
              visible={addPointVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {favoriteVisible && (
            <FavoriteModel
              visible={favoriteVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {footprintVisible && (
            <FootprintModel
              visible={footprintVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {salaryLogVisible && (
            <SalatyLogModal
              visible={salaryLogVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {blogLogVisible && (
            <BlogLogModal
              visible={blogLogVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {blockVisible && (
            <BlockModal
              visible={blockVisible}
              onClose={handleClose}
              userId={userId}
              nickName={user.nickName}
              avatar={user.avatar}
              labelDb={labelDb}
            />
          )}
          {pointLogVisible && (
            <PointGetModal
              visible={pointLogVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
              user={user}
            />
          )}
          {pointUsageVisible && (
            <PointUsageModal
              visible={pointUsageVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
            />
          )}
          {twilioHistoryVisible && (
            <TwilioHistoryModal
              visible={twilioHistoryVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
            />
          )}
          {favoriteJobVisible && (
            <FavoriteJob
              visible={favoriteJobVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
              user={user}
            />
          )}
          {jobEntryVisible && (
            <JobEntry
              visible={jobEntryVisible}
              onClose={handleClose}
              userId={userId}
              labelDb={labelDb}
              user={user}
            />
          )}
        </div>

        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={myProfVisible}
          onClose={() => setMyProfVisible(false)}
          aria-labelledby="max-width-dialog-title"
          PaperProps={{
            style: {
              top: 0,
              maxHeight: '100%',
              maxWidth: 600,
            },
          }}
          className={'d-modal-wrapper'}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          >
            マイプロフプレビュー
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={() => setMyProfVisible(false)}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent
            style={{
              marginBottom: 30,
              height: '80vh',
              overflow: 'hidden',
              padding: '8px 20px',
            }}
          >
            {user && (
              <iframe
                title="user-preview"
                id="iframeId"
                onLoad={accessAndPassData}
                style={{ border: 'none', width: '100%', height: '100%' }}
                src={user && Util.myProfSrc(user)}
              ></iframe>
            )}
          </DialogContent>
        </Dialog>

        <LoadingBar show={loading} />
        {user && (
          <MuiThemeProvider theme={customTheme}>
            {!user.archive && (
              <div
                id="max-width-dialog-title"
                className="d-box-content-action-user"
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: 'flow-root',
                }}
              >
                {(admin?.memberType === 0 ||
                  chatLogButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 ml-3 btn-action-chat-log"
                    onClick={
                      admin?.memberType === 0 ||
                      chatLogButton?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickChat
                        : ''
                    }
                  >
                    {chatLogButton?.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  likeButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-like"
                    onClick={
                      admin?.memberType === 0 ||
                      likeButton?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickLike
                        : ''
                    }
                  >
                    {likeButton?.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  favoriteButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-favorite"
                    onClick={
                      admin?.memberType === 0 ||
                      favoriteButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickFavorite
                        : ''
                    }
                  >
                    {favoriteButton.content}
                  </Button>
                )}

                {(admin?.memberType === 0 ||
                  footprintButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-foot-print"
                    onClick={
                      admin?.memberType === 0 ||
                      footprintButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickFootprint
                        : ''
                    }
                  >
                    {footprintButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  blogLogButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-blog-log"
                    onClick={
                      admin?.memberType === 0 ||
                      blogLogButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickBlogLog
                        : ''
                    }
                  >
                    {blogLogButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  blockButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-block"
                    onClick={
                      admin?.memberType === 0 ||
                      blockButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickBlock
                        : ''
                    }
                  >
                    {blockButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  paymentLogButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-payment-log"
                    onClick={
                      admin?.memberType === 0 ||
                      paymentLogButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickPoint
                        : ''
                    }
                  >
                    {paymentLogButton.content}
                  </Button>
                )}

                {(admin?.memberType === 0 ||
                  pointLogButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-point-log"
                    onClick={
                      admin?.memberType === 0 ||
                      pointLogButton?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickPointLog
                        : ''
                    }
                  >
                    {pointLogButton?.content}
                  </Button>
                )}

                {(admin?.memberType === 0 ||
                  pointUsageButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-point-usage"
                    onClick={
                      admin?.memberType === 0 ||
                      pointUsageButton?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickPointUsage
                        : ''
                    }
                  >
                    {pointUsageButton?.content}
                  </Button>
                )}

                {(admin?.memberType === 0 ||
                  salaryLogButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-salary"
                    onClick={
                      admin?.memberType === 0 ||
                      salaryLogButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickSalaryLog
                        : ''
                    }
                  >
                    {salaryLogButton.content}
                  </Button>
                )}

                {(admin?.memberType === 0 ||
                  twilioHistoryButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-twilio"
                    onClick={
                      admin?.memberType === 0 ||
                      twilioHistoryButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickTwilioHistory
                        : ''
                    }
                  >
                    {twilioHistoryButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  favoritejobButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-twilio"
                    onClick={
                      admin?.memberType === 0 ||
                      favoritejobButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickFavoriteJob
                        : ''
                    }
                  >
                    {favoritejobButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  jobEntryButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-twilio"
                    onClick={
                      admin?.memberType === 0 ||
                      jobEntryButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickJobEntry
                        : ''
                    }
                  >
                    {jobEntryButton.content}
                  </Button>
                )}
                <Button
                  onClick={handleMyProfile}
                  variant="contained"
                  className="btn-custom btn-blue mr-3 mt-3 btn-action-preview-profile"
                  style={{ float: 'right' }}
                >
                  マイプロフプレビュー
                </Button>
                {(admin?.memberType === 0 ||
                  editButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-action-edit"
                    style={{ float: 'right' }}
                    onClick={
                      admin?.memberType === 0 ||
                      editButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? () => redirectPageEditUser(user.id)
                        : ''
                    }
                  >
                    {editButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  updateProfilePageButton?.type !==
                    DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-push-sms"
                    style={{ float: 'right' }}
                    onClick={
                      admin?.memberType === 0 ||
                      updateProfilePageButton.type !==
                        DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickSmsDefault
                        : ''
                    }
                  >
                    {updateProfilePageButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  sendNotiButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-push-notify"
                    style={{ float: 'right' }}
                    onClick={
                      admin?.memberType === 0 ||
                      sendNotiButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickNoti
                        : ''
                    }
                  >
                    {sendNotiButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  sendEmailButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-push-email"
                    style={{ float: 'right' }}
                    onClick={
                      admin?.memberType === 0 ||
                      sendEmailButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickEmail
                        : ''
                    }
                  >
                    {sendEmailButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  sendSmsButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-push-sms"
                    style={{ float: 'right' }}
                    onClick={
                      admin?.memberType === 0 ||
                      sendSmsButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickSms
                        : ''
                    }
                  >
                    {sendSmsButton.content}
                  </Button>
                )}
                {(admin?.memberType === 0 ||
                  addPointButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                  <Button
                    variant="contained"
                    className="btn-custom btn-blue mr-3 mt-3 btn-add-point"
                    style={{ float: 'right' }}
                    onClick={
                      admin?.memberType === 0 ||
                      addPointButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? handleClickAddPoint
                        : ''
                    }
                  >
                    {addPointButton.content}
                  </Button>
                )}
              </div>
            )}
            <Row style={{ marginBottom: 50, marginTop: user.archive ? 20 : 0 }}>
              <Col xs={4}>
                {/*ImageList*/}
                <Card body className="mb-4 d-item-1">
                  <ImageList user={user} />
                </Card>
                <Card body className="mb-4 d-item-2">
                  <Row>
                    <Label style={style} xs={5}>
                      <p style={{ paddingLeft: 3, color: colorLabel }}>
                        MySQL ID
                      </p>
                      <hr style={stylehrleft} />
                    </Label>
                    <Label style={style} xs={7}>
                      <div
                        onClick={copyToClipboard(user.id)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{user.id}</span>
                        <span id="copy-btn">
                          {' '}
                          <CopyIcon style={{ fontSize: 18 }} />{' '}
                        </span>
                      </div>
                      <hr style={stylehrright} />
                    </Label>
                    <Label style={style} xs={5}>
                      <p style={{ paddingLeft: 3, color: colorLabel }}>
                        メールアドレス（重複NG）
                      </p>
                      <hr style={stylehrleft} />
                    </Label>
                    <Label style={style} xs={7}>
                      {user.emailAddressMain}
                      <hr style={stylehrright} />
                    </Label>
                    <Label style={style} xs={5}>
                      <p style={{ paddingLeft: 3, color: colorLabel }}>
                        メールアドレス状態
                      </p>
                      <hr style={stylehrleft} />
                    </Label>
                    <Label style={style} xs={7}>
                      {convertEmailAddressMainStatusText(+user.sendgridStatus)}
                      <hr style={stylehrright} />
                    </Label>

                    <Label style={style} xs={5}>
                      <p style={{ paddingLeft: 3, color: colorLabel }}>
                        広告コード
                      </p>
                      <hr style={stylehrleft} />
                    </Label>
                    <Label style={style} xs={7}>
                      {user.adcode}
                      <hr style={stylehrright} />
                    </Label>
                    <Label style={style} xs={5}>
                      <p style={{ paddingLeft: 3, color: colorLabel }}>
                        オペレーターNumber
                      </p>
                      <hr style={stylehrleft} />
                    </Label>
                    <Label style={style} xs={7}>
                      {user.operator?.operatorNumber}
                      <hr style={stylehrright} />
                    </Label>
                    <Label style={style} xs={5}>
                      <p style={{ paddingLeft: 3, color: colorLabel }}>
                        会社Number
                      </p>
                      <hr style={stylehrleft} />
                    </Label>
                    <Label style={style} xs={7}>
                      {user.agent?.agentNumber}
                      <hr style={stylehrright} />
                    </Label>
                  </Row>
                </Card>
                {/* Notify */}
                <Card body className="mb-4 d-item-3">
                  <Row>
                    <div
                      style={{
                        width: '100%',
                        flexDirection: 'column',
                        display: 'flex',
                      }}
                    >
                      <div style={{ width: '100%', marginBottom: 5 }}>
                        <Label style={style} xs={12}>
                          <p
                            style={{
                              paddingLeft: 3,
                              paddingTop: 5,
                              marginBottom: 0,
                              color: colorLabel,
                            }}
                          >
                            通知設定
                          </p>
                        </Label>
                        <Label style={style} xs={12}>
                          <div className="wrapper-tag">
                            {Object.keys(NOTIFY_FILED_TYPES).map((key) => {
                              const item = NOTIFY_FILED_TYPES[key];
                              return (
                                <div
                                  className={[
                                    'tag',
                                    +user?.notification?.[item] ===
                                    ON_OFF_TYPE.ON
                                      ? 'd-blue-active'
                                      : '',
                                  ].join(' ')}
                                >
                                  {convertSettingNotifyText(item)}
                                </div>
                              );
                            })}
                          </div>
                        </Label>
                      </div>
                      <hr
                        style={{
                          ...stylehrright,
                          width: '94%',
                          alignItems: 'center',
                          position: 'unset',
                          margin: '0px auto',
                        }}
                      />
                    </div>
                  </Row>
                </Card>

                {/*Tag list*/}
                <Card body className="mb-4 d-item-3">
                  <Row>
                    <div
                      style={{
                        width: '100%',
                        flexDirection: 'column',
                        display: 'flex',
                      }}
                    >
                      <div style={{ width: '100%', marginBottom: 5 }}>
                        <Label style={style} xs={12}>
                          <p
                            style={{
                              paddingLeft: 3,
                              paddingTop: 5,
                              marginBottom: 0,
                              color: colorLabel,
                            }}
                          >
                            タグ設定（プロフ）
                          </p>
                        </Label>
                        {user?.tags && user?.tags.length ? (
                          <Label style={style} xs={12}>
                            <div className="wrapper-tag">
                              {orderBy(
                                user?.tags ?? [],
                                ['displayOrder'],
                                ['asc'],
                              ).map((v, i) => (
                                <div className="tag" key={i}>
                                  {v.label}
                                </div>
                              ))}
                            </div>
                          </Label>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <hr
                        style={{
                          ...stylehrright,
                          width: '94%',
                          alignItems: 'center',
                          position: 'unset',
                          margin: '0px auto',
                        }}
                      />
                    </div>
                  </Row>
                </Card>

                {/*Tag admin list*/}
                <Card body className="mb-4 d-item-3">
                  <Row>
                    <div
                      style={{
                        width: '100%',
                        flexDirection: 'column',
                        display: 'flex',
                      }}
                    >
                      <div style={{ width: '100%', marginBottom: 5 }}>
                        <Label style={style} xs={12}>
                          <p
                            style={{
                              paddingLeft: 3,
                              paddingTop: 5,
                              marginBottom: 0,
                              color: colorLabel,
                            }}
                          >
                            タグ設定（管理者）
                          </p>
                        </Label>
                        {user?.tagsAdmin && user?.tagsAdmin.length ? (
                          <Label style={style} xs={12}>
                            <div className="wrapper-tag">
                              {orderBy(
                                user?.tagsAdmin ?? [],
                                ['displayOrder'],
                                ['asc'],
                              ).map((v, i) => (
                                <div className="tag" key={i}>
                                  {v.label}
                                </div>
                              ))}
                            </div>
                          </Label>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <hr
                        style={{
                          ...stylehrright,
                          width: '94%',
                          alignItems: 'center',
                          position: 'unset',
                          margin: '0px auto',
                        }}
                      />
                    </div>
                  </Row>
                </Card>

                <Card body className="mb-4 d-item-4">
                  <Row>{resource && showResource()}</Row>
                </Card>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Col xs={6}>
                          <Row>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                {labelDb?.label_nickName?.content}
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.nickName}
                              <hr style={stylehrright} />
                            </Label>

                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                フリガナ
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.realNameHurigana}&nbsp;
                              <hr style={stylehrright} />
                            </Label>

                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                電話番号（重複NG）
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.phoneNumber}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                パスワード(数字6桁)
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.password}&nbsp;&nbsp;&nbsp;{' '}
                              <hr style={stylehrright} />
                            </Label>

                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                ログインSMS必要
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertNeedOtpText(+user?.needOtp)}
                              <hr style={stylehrright} />
                            </Label>

                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                郵便番号
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.postCode}&nbsp;
                              <hr style={stylehrright} />
                            </Label>

                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                住所
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {user.address}&nbsp;
                              <hr style={stylehrright} />
                            </Label>
                          </Row>
                        </Col>
                        <Col xs={6}>
                          <Row>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                性別
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {Util.genderIdToText(user.gender)}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                キャラクターステイタス
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertCharaStatusText(+user.charaStatus)}
                              <hr style={stylehrright} />
                            </Label>

                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                年齢 生年月日
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {Util.calcAge(
                                Util.timestampToDate(
                                  user.birthday,
                                  'YYYY-MM-DD',
                                ),
                              )}
                              歳{' '}
                              {Util.timestampToDate(
                                user.birthday,
                                'YYYY/MM/DD',
                              )}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                会員種類
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {Util.memberTypeToText(user.memberType)}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                登録種類
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertMemberCategoryTypeText(
                                user.memberCategory,
                              )}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                新規登録時環境
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertRegisterTypeText(+user?.registerType)}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                電話認証状態
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertRegisterStatusText(+user.registerStatus)}
                              <hr style={stylehrright} />
                            </Label>
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                ランク
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            {rankOptions && (
                              <Label style={style} xs={7}>
                                {user.rankName?.label}
                                <hr style={stylehrright} />
                              </Label>
                            )}
                            <Label style={style} xs={5}>
                              <p style={{ paddingLeft: 3, color: colorLabel }}>
                                待機状態
                              </p>
                              <hr style={stylehrleft} />
                            </Label>
                            <Label style={style} xs={7}>
                              {convertStandByText(+user?.standBy)}
                              <hr style={stylehrright} />
                            </Label>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            都道府県
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.states?.label}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            エリア
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user?.area_label}
                          <hr style={stylehrright} />
                        </Label>
                        {renderTimestampField(timestampDataArray)}
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            月額会員期限
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.planExpireDate &&
                            Util.timestampToDate(
                              user.planExpireDate,
                              'YYYY-MM-DD',
                            )}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            表示ステイタス（非表示ログインNG）
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.memberStateToText(user.memberStatus)}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            年齢認証
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {convertInspectionStatusText(user.ageverification)}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            ログイン制御
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.loginControlToText(Number(user?.logincontrol))}
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Card>
                  </Col>

                  <Col xs={6}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            ポイント
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.separator(user.depositpoint)}p
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            給与ポイント
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.separator(user.salarypoint)}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            ログイン回数
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.separator(user.countLogin)}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            入金回数
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countPaying}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            入金総額
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {Util.separator(user.totalPaying)}
                          円
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            チャット受信数
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countTextReceived}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            チャット送信数
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countTextSending}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            画像受信数
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countPhotoReceived}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            画像送信数
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countPhotoSending}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            コール受信回数
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countCallReceived}
                          <hr style={stylehrright} />
                        </Label>
                        <Label style={style} xs={5}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            コール発信回数
                          </p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label style={style} xs={7}>
                          {user.countCallSending}
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={2}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            自己PR
                          </p>
                        </Label>
                        <Label
                          style={{
                            ...style,
                            height: 'auto',
                            whiteSpace: 'pre-line',
                          }}
                          xs={10}
                        >
                          {user.aboutMe}
                        </Label>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={2}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            管理者メモ
                          </p>
                        </Label>
                        <Label
                          style={
                            (style, { height: 'auto', whiteSpace: 'pre-line' })
                          }
                          xs={10}
                        >
                          {user.adminMemo}
                        </Label>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card body className="mb-4">
                      <Row>
                        <Label style={style} xs={2}>
                          <p style={{ paddingLeft: 3, color: colorLabel }}>
                            新規登録時情報
                          </p>
                        </Label>
                        {user.registerInfo &&
                        IsJsonString(user.registerInfo) ? (
                          <Label
                            style={
                              (style,
                              { height: 'auto', whiteSpace: 'pre-line' })
                            }
                            xs={10}
                          >
                            <p style={{ paddingLeft: 3 }}>
                              IP : {JSON.parse(user.registerInfo).ip}
                            </p>
                            <p style={{ paddingLeft: 3 }}>
                              Browser: {JSON.parse(user.registerInfo).browser}
                            </p>
                          </Label>
                        ) : (
                          <Label
                            style={
                              (style,
                              { height: 'auto', whiteSpace: 'pre-line' })
                            }
                            xs={10}
                          >
                            <p style={{ paddingLeft: 3 }}>
                              {user.registerInfo}
                            </p>
                          </Label>
                        )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            {(admin?.memberType === 0 ||
              deleteButton.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                variant="contained"
                className="btn-custom btn-red"
                style={{ position: 'absolute', bottom: 20, right: 25 }}
                onClick={
                  admin?.memberType === 0 ||
                  deleteButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleRemoveUser
                    : ''
                }
              >
                {deleteButton.content}
              </Button>
            )}
            {!user.archive &&
              (admin?.memberType === 0 ||
                moveToArchiveButton?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                <Button
                  variant="contained"
                  className="btn-custom btn-red"
                  style={{ position: 'absolute', bottom: 20, right: 100 }}
                  onClick={
                    admin?.memberType === 0 ||
                    moveToArchiveButton?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                      ? handleMoveToArchiveUser
                      : ''
                  }
                >
                  {moveToArchiveButton?.content}
                </Button>
              )}
          </MuiThemeProvider>
        )}
      </div>
    </>
  );
}

export default Index;
