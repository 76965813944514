import React, { useEffect, useState } from 'react';
import { Button, TextField, FormLabel } from '@material-ui/core';
import { Row, Col, FormGroup } from 'reactstrap';
import { COLUMN_DEFINED_SEARCH_BOX } from '../../../Common/constant';
import RadioGroup from '../../../Components/RadioGroup';
import { emailSendCategoryRequired } from '../../../Util/utils';
import { LOG_SEND_MAIL_TYPE } from 'Common/constant_text';

const DEFAULT_FILTER_STATE = {
  sender_phone: '',
  nick_name: '',
  created_date_from: '',
  created_date_to: '',
  type: undefined,
  category: undefined,
};
function SearchBox(props) {
  const { labelDb } = props;
  const [filter, setFilter] = useState(DEFAULT_FILTER_STATE);

  const onChange = (event) => {
    event.persist();
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value || undefined,
    }));
  };

  const handleSearch = () => {
    try {
      return props.onSubmit(filter);
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    setFilter(DEFAULT_FILTER_STATE);
  };

  return (
    <React.Fragment>
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1}>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  value={filter.sender_phone}
                  label="電話番号"
                  type="text"
                  name="sender_phone"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  value={filter.nick_name}
                  label={labelDb?.label_nickName?.content}
                  type="text"
                  name="nick_name"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2}>
            <FormGroup row>
              <Col xs={5}>
                <TextField
                  value={filter.created_date_from}
                  id="date"
                  label="登録日"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="created_date_from"
                  onChange={onChange}
                />
              </Col>
              <Col xs={2} className="flex-end d-col-2-symbol">
                〜
              </Col>
              <Col xs={5}>
                <TextField
                  value={filter.created_date_to}
                  id="date"
                  label="登録日"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="created_date_to"
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3}>
            <FormGroup row>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">タイプ</FormLabel>
                <RadioGroup
                  controlled
                  name="type"
                  defaultValue={filter.type}
                  options={[
                    { value: undefined, label: '全て' },
                    ...LOG_SEND_MAIL_TYPE,
                  ]}
                  onChange={onChange}
                  classCustom="d-cus-item"
                />
              </Col>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">カテゴリー</FormLabel>
                <RadioGroup
                  controlled
                  name="category"
                  defaultValue={filter.category}
                  options={emailSendCategoryRequired()}
                  onChange={onChange}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchBox;
