import {
  Button,
  Card,
  createMuiTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import NoPhoto from '../../assets/image/no_user_photo.png';
import moment from 'moment';
import _ from 'lodash';
import RemoveModal from './RemoveModal';
import { TEXT_HTML_MANAGEMENT_CHAT_ROOM_LINK_MODAL } from '../../Common/modal_text_info';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function ChatRoomDetailModal({ onClose, conversation, reload }) {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemove = () => {
    setConfirmVisible(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <RemoveModal
          visible={confirmVisible}
          onClose={handleClose}
          conversationId={conversation?.id}
          reload={reload}
          onCloseDetail={onClose}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={!!conversation}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>チャットルーム管理-詳細</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p style={{ fontSize: '1rem', marginRight: 20, marginBottom: 0 }}>
                {conversation?.sender?.phoneNumber}
              </p>
              <img
                className="object-fit-cover"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: '50%',
                  marginRight: 20,
                }}
                src={
                  conversation?.sender?.memberStatus ===
                  MEMBER_USER_STATUS.IN_ACTIVE
                    ? UserDelete
                    : conversation?.sender?.avatar
                      ? conversation?.sender?.avatar?.url
                      : NoPhoto
                }
                alt=""
              />
              <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                {conversation?.sender?.nickName}
              </p>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{
              __html: TEXT_HTML_MANAGEMENT_CHAT_ROOM_LINK_MODAL,
            }}
          ></div>
        </div>

        <DialogContent>
          <MuiThemeProvider theme={theme}>
            <Row>
              <Col xs={12}>
                <Card
                  className="card card-body"
                  style={{ marginBottom: '1rem' }}
                >
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>登録日（開設日）</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>
                            {moment(
                              Number(_.get(conversation, 'createdDate')),
                            ).format('YYYY-MM-DD HH:mm:ss')}
                          </p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>更新日時</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>
                            {moment(
                              Number(_.get(conversation, 'updatedDate')),
                            ).format('YYYY-MM-DD HH:mm:ss')}
                          </p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>受信者ネックネーム</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{conversation?.receiver?.nickName}</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>受信者電話番号</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{conversation?.receiver?.phoneNumber}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Button
              variant="contained"
              className="btn-custom btn-red float-right"
              onClick={handleRemove}
              style={{ marginBottom: 2 }}
            >
              削除
            </Button>
          </MuiThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
