import React, { useState, useEffect } from 'react';
import LoadingBar from '../../../Components/LoadingBar';
import { useSelector, useDispatch } from 'react-redux';
import { loadLogSms } from '../../../Redux/Actions/logSendSms';
import LogSendList from './LogSendList';
import Pagination from '../../../Components/Pagination';
import LogSendDetailModal from './LogSendDetailModal';
import { convertEnvInfo } from '../../../Util/utils';

const pageSize = 50;
const mutating = false;

function LogSendSms() {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.logSendSms.loading);
  const logSendSms = useSelector((state) => state.logSendSms.items);
  const totalRow = useSelector((state) => state.logSendSms.totalRow);
  const [logSendDetail, setLogSendDetail] = useState(null);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    sort: { 'logSendSms.createdDate': 'DESC' },
  });

  useEffect(() => {
    dispatch(loadLogSms({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (
      filter.sort[sortByKey] === undefined ||
      filter.sort[sortByKey] === 'DESC'
    ) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const handleClickDetail = (logSend) => {
    setLogSendDetail(logSend);
  };

  const handleClose = () => {
    setLogSendDetail(null);
  };

  const reload = () => {
    dispatch(loadLogSms({ pageIndex: 1, pageSize, ...filter }));
  };

  const onSearch = (searchData) => {
    setFilter({ ...searchData });
  };

  const onClear = () => {
    setFilter({ sort: { 'LogSendNoti.createdDate': 'DESC' } });
  };

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      {logSendDetail && (
        <LogSendDetailModal
          onClose={handleClose}
          logSend={logSendDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}
      <LogSendList
        loading={loading}
        logSendSms={logSendSms}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
        onSearch={onSearch}
        onClear={onClear}
        labelDb={labelDb}
      />
      {!loading && (
        <center>
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRow={totalRow}
            onPageChange={handlePageChange}
          />
        </center>
      )}
    </div>
  );
}
export default LogSendSms;
