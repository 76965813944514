import {
  Button,
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../Components/LoadingBar';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadPointRate, updatePointRate } from '../../Redux/Actions/pointRate';
import Util from '../../Util/Util';
import { PersonPinOutlined } from '@material-ui/icons';
import { PAYMENT_STATUS } from 'Common/constant_text';

const PointRate = () => {
  const loading = useSelector((state) => state.pointRate.loading);
  const pointRate = useSelector((state) => state.pointRate.data);
  const [isEditing, setIsEditing] = useState(false);
  const [envSetting, setEnvSetting] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPointRate());
  }, [dispatch]);

  useEffect(() => {
    setEnvSetting(pointRate);
  }, [pointRate]);

  const onClose = () => {
    setInputUpdate([]);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    dispatch(updatePointRate(inputUpdate));
    setEnvSetting(inputUpdate);

    setIsEditing(false);
  };

  const handleEdit = () => {
    setInputUpdate(envSetting);
    setIsEditing(true);
  };

  const handleChange = (e) => {
    if (
      e.target.value !== '' &&
      e.target.value != null &&
      e.target.value !== undefined
    ) {
      const splitName = e.target.name.split('/');
      const newArray = [...inputUpdate];
      const elementsIndex = envSetting.findIndex(
        (element) => element.id === splitName[1],
      );
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        [splitName[0]]: e.target.value,
      };
      setInputUpdate(newArray);
    }
  };

  const createComment = (data) => {
    const comment = data?.id
      ?.replace('stripe.rate', 'WEB-Stripe課金レート')
      .replace('googlepay.rate', 'APP-Google課金レート')
      .replace('bitcash.rate', 'Web-BitCash課金レート');
    return comment || '';
  };

  const renderPaymentStatus = (paymentType) => {
    const payment = PAYMENT_STATUS.find((e) => e.value === +paymentType);
    return payment?.label || '';
  };

  const renderDisplay = (display) => {
    switch (display) {
      case 0:
        return '表示';
      case 1:
        return '非表示';
      default:
        return '';
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <LoadingBar show={loading} />

      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top main-content-custom">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>説明</TableCell>
                <TableCell>バナー画像</TableCell>
                <TableCell>DATA名</TableCell>
                <TableCell>決済種類</TableCell>
                <TableCell>決済金額</TableCell>
                <TableCell>ポイント</TableCell>
                <TableCell>ボーナスポイント</TableCell>
                <TableCell>表示設定</TableCell>
                <TableCell>表示順(管理）</TableCell>
              </TableRow>
              {envSetting?.map((x, index) => (
                <TableRow key={x.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{createComment(x)}</TableCell>
                  <TableCell align="center">
                    {!isEditing ? (
                      <img
                        style={{
                          height: 100,
                          width: '100%',
                          objectFit: 'contain',
                        }}
                        src={x.urlImage}
                        alt=""
                      />
                    ) : (
                      <input
                        style={{ minWidth: 200 }}
                        defaultValue={x.urlImage}
                        name={`urlImage/${x.id}`}
                        onChange={handleChange}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        style={{ minWidth: 200 }}
                        defaultValue={x.googlePayProductId}
                        name={`googlePayProductId/${x.id}`}
                        onChange={handleChange}
                      />
                    ) : (
                      x.googlePayProductId
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        defaultValue={x.paymentType}
                        name={`paymentType/${x.id}`}
                        onChange={handleChange}
                      />
                    ) : (
                      renderPaymentStatus(x.paymentType)
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        defaultValue={x.price}
                        name={`price/${x.id}`}
                        onChange={handleChange}
                      />
                    ) : (
                      Util.separator(x.price)
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        defaultValue={x.point}
                        name={`point/${x.id}`}
                        onChange={handleChange}
                      />
                    ) : (
                      Util.separator(x.point)
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        defaultValue={x.pointBonus}
                        name={`pointBonus/${x.id}`}
                        onChange={handleChange}
                      />
                    ) : (
                      Util.separator(x.pointBonus)
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <input
                        type="number"
                        defaultValue={Number(x.displaySetting)}
                        name={`displaySetting/${x.id}`}
                        onChange={handleChange}
                      />
                    ) : (
                      renderDisplay(Number(x.displaySetting))
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: 100,
                    }}
                  >
                    {isEditing ? (
                      <input
                        defaultValue={x.display_order}
                        name={`display_order/${x.id}`}
                        onChange={handleChange}
                      />
                    ) : (
                      x.display_order
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
};

export default PointRate;
