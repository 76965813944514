import { LOGIN_TYPE } from '../Common/constant';

export const PROFILE_TYPE = {
  ROOT: 0,
};

export const ITEM_JOB_TYPE = {
  ROOT: 0,
};

export const IMAGE_STATUS = {
  PENDING: 1,
  CONFIRMED: 2,
  ALL: 3,
};

export const IMAGE_TYPE = {
  AVATAR: 1,
  SUB_IMAGE: 2,
};

export const REGISTER_TYPE = {
  WEB: 1,
  APP: 2,
  CMS: 9,
};

export const TAG_SETTING = {
  DISPLAY: 0,
  HIDDEN: 1,
};

export const TAG_TYPE = {
  USER: 1,
};

export const AGENT_ACCESS_MENU_LINKS = ['jobManagement'];

export const LINK_DISPLAY_PAGE_AFTER_LOGIN = {
  1: '/home',
  2: '/mypage',
  3: '/mypage-job',
  4: '/job-lp',
};

export const LINK_MAP_DEFINED_API = {
  OPERATOR: 'operator',
  AGENT_DETAIL_DELETE: 'agent/detail/delete',
  AGENT_DETAIL_CREATE_LP: 'agent/detail/create_lp',
  AGENT_BTN_CHARA_LIST: 'agent_btn_chara_list',
  AGENT_BTN_POINT_USAGE: 'agent_btn_point_usage',
  AGENT_BTN_JOB_LIST: 'agent_btn_job_list',
  AGENT_BTN_FAVORITE_LOG_MODAL: 'agent_btn_favorite_log_modal',
  AGENT_BTN_POINT_MODAL: 'agent_btn_point_modal',
  AGENT_BTN_ENTRY_PAIL_PLAN_MODAL: 'agent_btn_entry_pail_plan_modal',
  AGENT_BTN_PAYMENT_MODAL: 'agent_btn_payment_modal',
  AGENT_BTN_BALANCE_MODAL: 'agent_btn_balance_modal',
  AGENT_BTN_CHAT_ROOM_MODAL: 'agent_btn_chat_room_modal',
  FILE_UPLOAD_WEB: 'file_upload/web',
  FILE_UPLOAD_APP: 'file_upload/app',
  FILE_UPLOAD_CONTENT_IMAGE_WEB: 'file_upload/content_image_web',
  INFORMATION_HOME: 'information_home',
  HOME: 'home',

  ADMINISTRATOR_DETAIL_DELETE: 'administrator/detail/delete',
  ADMIN_OPE_SEARCH_PRESET: 'admin_ope/search_user/preset',
  ADMIN_OPE_SEARCH_USER_SMS_CHAT: 'admin_ope/search_user/sms_chat',
  ADMIN_OPE_SEARCH_USER_SCHEDULE_SMS:
    'admin_ope/search_user/schedule_sms_standby',
  ADMIN_OPE_SEARCH_USER_SCHEDULE_MAIL:
    'admin_ope/search_user/schedule_mail_standby',
  OPERATOR_DETAIL_DELETE: 'operator/detail/delete',
  OPERATOR_DETAIL_WORK_PAGE: 'operator/detail/work_page',
  OPE_BTN_POINT_USAGE: 'ope_btn_point_usage',
  OPE_BTN_FAVORITE_JOB: 'ope_btn_favorite_job',
  OPE_BTN_ENTRY_PAID_PLAN: 'ope_btn_entry_paid_plan',
  OPE_BTN_BALANCE: 'ope_btn_balance',
  OPE_BTN_CHAT_ROOM_ADMIN: 'ope_btn_chat_room_admin',

  MANAGEMENT_SEND_MAIL_LOG: 'management/send_mail_log',
  MANAGEMENT_SEND_SMS_LOG: 'management/send_sms_log',
  MANAGEMENT_SEND_PUSH_LOG: 'management/send_push_log',
  MANAGEMENT_TIMER_MAIL_STANDBY: 'management/timer_mail_standby',
  MANAGEMENT_TIMER_SMS_STANDBY: 'management/timer_sms_standby',
  MANAGEMENT_TIMER_PUSH_STANDBY: 'management/timer_push_standby',
  MANAGEMENT_TIMER_CHATROOM_STANDBY: 'management/timer_chatroom_standby',
  MANAGEMENT_SCHEDULE_MAIL_STANDBY: 'management/schedule-mail-standby',
  MANAGEMENT_SCHEDULE_SMS_STANDBY: 'management/schedule-sms-standby',

  USER_SEARCH_DETAIL_CHAT_LOG: 'user/search/detail/chat_log',
  USER_SEARCH_DETAIL_LIKE: 'user/search/detail/like',
  USER_SEARCH_DETAIL_PAYMENT_LOG: 'user/search/detail/payment_log',
  USER_SEARCH_DETAIL_NOTIFY_SEND: 'user/search/detail/noti_send',
  USER_SEARCH_DETAIL_EMAIL_SEND: 'user/search/detail/email_send',
  USER_SEARCH_DETAIL_SMS_SEND: 'user/search/detail/sms_send',
  USER_SEARCH_UPDATE_PROFILE_PAGE_MEMBER:
    'user/search/detail/update_profile_page_member',
  USER_SEARCH_DETAIL_EDIT: 'user/search/detail/edit',
  USER_SEARCH_DETAIL_DELETE: 'user/search/detail/delete',
  USER_SEARCH_DETAIL_ADD_POINT: 'user/search/detail/add_point',
  USER_SEARCH_DETAIL_FAVORITE: 'user/search/detail/favorite',
  USER_SEARCH_DETAIL_FOOTPRINT: 'user/search/detail/footprint',
  USER_SEARCH_DETAIL_SALARY_LOG: 'user/search/detail/salary_log',
  USER_SEARCH_DETAIL_BLOG_LOG: 'user/search/detail/blog_log',
  USER_SEARCH_DETAIL_BLOCK: 'user/search/detail/block',
  USER_SEARCH_DETAIL_POINT_LOG: 'user/search/detail/point_log',
  USER_SEARCH_DETAIL_POINT_USAGE: 'user/search/detail/point_usage',
  USER_SEARCH_DETAIL_MOVE_TO_ARCHIVE: 'user/search/detail/move-to-archive',
  USER_SEARCH_DETAIL_TWILIO_LOG: 'user/search/detail/twilio_log',
  USER_SEARCH_BULK_CONVERSION: 'user/search/bulk_conversion',
  USER_SEARCH_EMAIL_SEND: 'user/search/email_send',
  USER_SEARCH_EMAIL_LOOP_SEND: 'user/search/email_schedule_send',
  USER_SEARCH_NOTIFY_SEND: 'user/search/noti_send',
  USER_SEARCH_SMS_SEND: 'user/search/sms_send',
  USER_SEARCH_SMS_LOOP_SEND: 'user/search/sms-schedule-send',
  USER_SEARCH_PRESET: 'user/search/preset',
  USER_SEARCH_DETAIL_FAVORITE_JOB: 'user/search/detail/favorite_job',
  USER_SEARCH_DETAIL_JOB_ENTRY: 'user/search/detail/job_entry',
  USER_SEARCH_CSV_EXPORT: 'user/search/csv_export',
  USER_SEARCH_CSV_IMPORT: 'user/search/import-csv',
  USER_SEARCH_ARCHIVE_USERS: 'user/search/archive_users',
  USER_SEARCH_DELETE_USERS: 'user/search/delete_users',

  CONTACTS: 'contacts',
  CHAT_SUPPORT_INDIVIDUAL: 'chat_support_individual',
  CHAT_ADMIN_INDIVIDUAL: 'chat_admin_individual',
};

export const CODE_MAP_DEFINED_API = {
  SITE_NAME: 'site_name',
  CONTACT_MAIL_ADDRESS: 'contact_mail_address',
  URL_LP: 'url_lp',
  CONTACT_PHONE_NUMBER: 'contact_phone_number',
  URL_CONTACT: 'url_contact',
  BG_COLOR_SIDE_NAV: '99',
  LOGIN_LOGO: 'login/logo',
  LOGIN_TITLE: 'login/title',
  CMS_TITLE_NAME: 'cms_title_name',
  TOP_NAVI_BAR_LOGO: 'top_navibar_logo',

  // Action type login
  SYSTEM_ADMIN: String(LOGIN_TYPE.SYSTEM_ADMIN), // 0
  OPERATOR: String(LOGIN_TYPE.OPERATOR), // 1,
  AGENT: String(LOGIN_TYPE.AGENT), // 2,
  ADMIN: String(LOGIN_TYPE.SYSTEM_ADMIN), // 3,
};

export const REGISTER_TYPE_LOG_ENROLLMENT = {
  WEB: 1,
  APP: 2,
  CSV: 3,
  CMS: 9,
};
