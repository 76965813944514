import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import * as imageApi from '../../../Api/imageApi';
import { changeImageStatus } from '../../../Api/imageApi';
import { toast } from 'react-toastify';
import { IMAGE_STATUS, IMAGE_TYPE } from '../../../Config/listConstants';
import { Card, Col, Row } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import Util from '../../../Util/Util';
import {
  TEXT_HTML_MANAGEMENT_IMAGE_PROF_LINK_MODAL,
  TEXT_HTML_MANAGEMENT_USER_SHOW_IMAGE_MAIN_LINK_MODAL,
  TEXT_HTML_MANAGEMENT_USER_SHOW_IMAGE_SUB_LINK_MODAL,
} from '../../../Common/modal_text_info';
import { style } from '../common-styles';
import {
  convertImageProfStatusText,
  convertProfImageMainTypesText,
  convertProfImageSubTypesText,
} from '../../../Util/utils';
import get from 'lodash/get';
import moment from 'moment';
import { PROF_IMAGE_MAIN_TYPE } from '../../../Common/constant';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../../Common/constant_text';
import RemoveItemModal from '../../../Components/RemoveItemModal';


function ModalShowImage(props) {
  const { image, onClose, onUpdate, type } = props;
  const [visible, setVisible] = useState(false);
  const [updating, setUpdating] = useState(false);

  const handleUpdate = () => {
    setUpdating(true);
    onUpdate();
    changeImageStatus({
      id: image.id,
      inspection:
        image.inspection === IMAGE_STATUS.PENDING
          ? IMAGE_STATUS.CONFIRMED
          : IMAGE_STATUS.PENDING,
    }).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        onUpdate();
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_EDIT);
      }
    });
  };

  const onHandleDelete = () => {
    onUpdate();
    setVisible(false);
    imageApi.deleteImage(image.id).then((response) => {
      const { data } = response;
      if (data.success) {
        imageApi.deleteImageStorage(Util.getFilePath(image.url)).then({});
        onClose();
        onUpdate();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  const renderContentDetailImageProf = () => {
    return (
      <Card body className="mb-4">
        <Row>
          <Col style={style} xs={6} className={'d-line-content d-flex d-justify-space-between'}>
            <div style={{ paddingLeft: 0, paddingRight: 0 }}>
              <p style={{ paddingLeft: 3 }}>日時</p>
            </div>
            <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
              <p>
                {moment(Number(get(image, 'createdDate'))).format('YYYY-MM-DD HH:mm:ss')}
              </p>
            </div>
          </Col>
          <Col style={style} xs={6} className={'d-line-content d-flex d-justify-space-between'}>
            <div style={{ paddingLeft: 0, paddingRight: 0 }}>
              <p style={{ paddingLeft: 3 }}>写真認証</p>
            </div>
            <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>

              {convertImageProfStatusText(+image.inspection)}
            </div>
          </Col>
        </Row>
        <Row className={'d-line-col'}>
          <Col style={style} xs={6} className={'d-line-content d-flex d-justify-space-between'}>
            <div style={{ paddingLeft: 0, paddingRight: 0 }}>
              <p style={{ paddingLeft: 3 }}>画像種類</p>
            </div>
            <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'end' }} className={'mr-1'}>
              <p>
                {image.type === IMAGE_TYPE.AVATAR ? convertProfImageMainTypesText(PROF_IMAGE_MAIN_TYPE.STYLE) : convertProfImageSubTypesText(image.subImgNumber)}
              </p>
            </div>
          </Col>
          <Col style={style} xs={6} className={'d-line-content'}>
            <div style={{ paddingLeft: 0, paddingRight: 0 }}>&nbsp;</div>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={true}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <p className={'d-title mb-0'}>プロフィール画像管理-詳細</p>
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
        </DialogTitle>
        {type && type === 'management_image_list' && (
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'}
                 dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_IMAGE_PROF_LINK_MODAL }}></div>
          </div>
        )}
        {type && type === 'user_edit_image_list' && (
          <div className={'d-title-info-box'}>
            {/*image main*/}
            {image && image?.type === IMAGE_TYPE.AVATAR && (
              <div className={'text mb-0'}
                   dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_USER_SHOW_IMAGE_MAIN_LINK_MODAL }}></div>
            )}
            {/*image sub*/}
            {image && image?.type === IMAGE_TYPE.SUB_IMAGE && (
              <div className={'text mb-0'}
                   dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_USER_SHOW_IMAGE_SUB_LINK_MODAL }}></div>
            )}
          </div>
        )}

        <DialogContent>
          {/*management_image_list*/}
          {type === 'management_image_list' && renderContentDetailImageProf()}

          <Card className="card card-body" style={{ marginBottom: 10 }}>
            <Row>
              <Col xs={12}>
                <img style={{
                  width: 250,
                  objectFit: 'cover',
                  margin: 2.5,
                  maxWidth: 400, maxHeight: 400,
                }}
                     src={image.url}
                     alt=""
                />
              </Col>
            </Row>
          </Card>
          <div className={'d-flex'} style={{ marginBottom: 15, justifyContent: 'space-between' }}>
            <div></div>
            <Button
              onClick={() => handleUpdate()}
              variant="contained"
              className="btn-custom btn-blue"
              disabled={updating}
            >
              {image.inspection === IMAGE_STATUS.PENDING
                ? '認証する'
                : '認証しない'}
            </Button>
            <Button
              onClick={() => setVisible(true)}
              variant="contained"
              className="btn-custom btn-red"
            >
              削除
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/*Confirm delete image*/}
      <RemoveItemModal visible={visible}
                       onClose={() => setVisible(false)}
                       handleSubmit={onHandleDelete}
                       title={MESSAGE_CONFIRM_DELETE.TITLE}
                       content={MESSAGE_CONFIRM_DELETE.CONTENT}
      />
    </div>
  );
}

export default ModalShowImage;
