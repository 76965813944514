import { Button, Card, createMuiTheme, Dialog, DialogContent, DialogTitle, MuiThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import moment from 'moment';
import get from 'lodash/get';
import { BLOG_TYPE, MEMBER_USER_STATUS } from '../../Common/constant';
import Util from '../../Util/Util';
import { removeMultiBlog } from '../../Api/blogAPI';
import { toast } from 'react-toastify';
import NoPhoto from '../../assets/image/no_user_photo.png';
import ImageModal from '../../Components/ImageModal';
import { deleteImageStorage } from '../../Api/imageApi';
import { TEXT_HTML_MANAGEMENT_BLOG_LOG_LINK_MODAL } from '../../Common/modal_text_info';
import { MESSAGE_CONFIRM_DELETE, TOAST_MESSAGE } from '../../Common/constant_text';
import RemoveItemModal from '../../Components/RemoveItemModal';
import UserDelete from '../../assets/image/delete_user.png';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function BlogDetailModal({ onClose, blog, reload }) {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [previewingImage, setPreviewingImage] = useState(null);

  const handleShowImageModal = (image) => {
    setPreviewingImage(image);
  };

  const handleHideImageModal = () => {
    setPreviewingImage(null);
  };

  const handleSubmit = async () => {
    const Ids = [blog?.id];
    await removeMultiBlog({ Ids }).then((response) => {
      const { data } = response;
      if (data.success) {
        if (blog?.type === BLOG_TYPE.IMAGE) {
          deleteImageStorage(Util.getFilePath(blog?.content)).then();
        }
        onClose();
        reload();
        toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_DELETE);
      }
    });
  };

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemoveBlog = () => {
    setConfirmVisible(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={!!blog}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>ブログログ-詳細</p>
            <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
              <p style={{ fontSize: '1rem', marginRight: 20, marginBottom: 0 }}>
                {blog?.sender?.phoneNumber}
              </p>
              <img className="object-fit-cover"
                   style={{
                     width: 30,
                     height: 30,
                     borderRadius: '50%',
                     marginRight: 20,
                   }}
                   src={
                    blog?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                    blog?.sender?.avatar ? blog?.sender?.avatar?.url : NoPhoto}
                   alt=""
              />
              <p style={{ fontSize: '1rem', marginBottom: 0 }}>
                {blog?.sender?.nickName}
              </p>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_BLOG_LOG_LINK_MODAL }}></div>
        </div>

        <DialogContent>
          <MuiThemeProvider theme={theme}>
            <Row>
              <Col xs={12}>
                <Card
                  className="card card-body"
                  style={{ marginBottom: '1rem' }}
                >
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>日時</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>
                            {moment(Number(get(blog, 'createdDate'))).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )}
                          </p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>種類</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>
                            {Util.blogTypeToText(blog?.type)}
                          </p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>×Status</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{blog?.status}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>×表示</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.hiddenToText(blog?.hidden)}</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>×認証</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.inspectionToText(blog?.inspection)}</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                {blog?.type === BLOG_TYPE.TEXT && (
                  <Card
                    className="card card-body"
                    style={{ marginBottom: '1rem' }}
                  >
                    <Row>
                      <Label xs={2}>本文</Label>
                      <Label xs={10} style={{ whiteSpace: 'pre-line' }}>
                        {get(blog, 'content')}
                      </Label>
                    </Row>
                  </Card>
                )}
                {blog?.type === BLOG_TYPE.IMAGE && (
                  <Card className="card card-body" style={{ marginBottom: 10 }}>
                    <Row>
                      <Col xs={12}>
                        <img
                          style={{
                            width: 250,
                            objectFit: 'cover',
                            margin: 2.5,
                          }}
                          src={blog?.content ?? NoPhoto}
                          alt=""
                          onClick={() => handleShowImageModal(blog)}
                        />
                      </Col>
                    </Row>
                  </Card>
                )}
              </Col>
            </Row>
            <Button
              variant="contained"
              className="btn-custom btn-red float-right"
              onClick={handleRemoveBlog}
              style={{ marginBottom: 2 }}
            >
              削除
            </Button>
            {previewingImage && (
              <ImageModal
                image={previewingImage}
                onClose={handleHideImageModal}
              />
            )}
          </MuiThemeProvider>
        </DialogContent>
      </Dialog>

      {/*remove blog*/}
      {confirmVisible && (
        <RemoveItemModal visible={confirmVisible}
                         onClose={handleClose}
                         handleSubmit={handleSubmit}
                         title={MESSAGE_CONFIRM_DELETE.TITLE}
                         content={MESSAGE_CONFIRM_DELETE.CONTENT}
        />
      )}
    </div>
  );
}
