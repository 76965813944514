import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CustomEditor from '../../Components/CustomEditor';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, Col, Label, Row } from 'reactstrap';
import { conversionTags } from '../../Api/conversionTagApi';
import { getEnvironment } from '../../Api/environmentApi';
import {
  getUser,
  sendEmailListUser,
  sendEmailUser,
  setTimeMail,
} from '../../Api/userAPI';
import {
  CONVERSION_TAG_URL,
  DISPLAY_OPTIONS,
  GENDER,
  TIMER_SENDING_TYPE,
} from '../../Common/constant';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import { TEXT_HTML_MANAGEMENT_USER_SEARCH_EMAIL_LINK_MODAL } from '../../Common/modal_text_info';
import LoadingBar from '../../Components/LoadingBar';
import RadioGroup from '../../Components/RadioGroup';
import { CODE_MAP_DEFINED_API } from '../../Config/listConstants';
import { loadEnvInfo } from '../../Redux/Actions/envInfo';
import { loadUserIds } from '../../Redux/Actions/users';
import Util from '../../Util/Util';
import {
  convertDisplayOptionsText,
  displayOptionsOptionsRequired,
} from '../../Util/utils';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

function SendEmailModal({ visible, onClose, filter }) {
  const [data, setData] = useState({});
  const [userIds, setUserIds] = useState([]);
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hourSend, setHourSend] = useState(moment().format('HH').toString());
  const [minusSend, setMinusSend] = useState(moment().format('mm').toString());
  const [dateSend, setDateSend] = useState(
    moment(new Date(moment().startOf('day')).getTime()).format('YYYY-MM-DD'),
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date(moment().startOf('day')).getTime(),
  );
  const [timeSend, setTimeSend] = useState();
  const [timerMail, setTimerMail] = useState(`${DISPLAY_OPTIONS.OFF}`);

  const [fromMails, setFromMails] = useState([]);
  const [contentHtml, setContentHtml] = useState();
  const [conversionTag, setConversionTag] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadEnvInfo());
  }, [dispatch]);

  const users = useSelector((state) => state.users.itemsUserIds);
  const totalRow = useSelector((state) => state.users.totalRowUserids);
  const envInfo = useSelector((state) => state.envInfo.data);

  const envInfoTag = envInfo.filter((item) =>
    [
      CODE_MAP_DEFINED_API.SITE_NAME,
      CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS,
      CODE_MAP_DEFINED_API.URL_LP,
      CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER,
      CODE_MAP_DEFINED_API.URL_CONTACT,
    ].includes(item.code),
  );

  useEffect(() => {
    dispatch(loadUserIds(filter));
    getEnvironment({}).then((res) => {
      const env = res;
      const sendGridAddess = Object.keys(env.data.data)
        .filter((t) => t.indexOf('sendgridAddress') > -1)
        .map((t) => {
          return {
            key: t,
            value: env.data.data[t],
          };
        });
      const sendGridAddressFirst = sendGridAddess?.[0];
      data['fromAddress'] = sendGridAddressFirst?.key;
      data['from_mail_address'] = sendGridAddressFirst?.value;

      setData(data);
      setFromMails(sendGridAddess);
    });
    conversionTags({}).then((res) => {
      const { data: tags } = res;
      setConversionTag(tags?.data || []);
    });
  }, [dispatch, filter]);

  useEffect(() => {
    const userIds = users.map((item) => item.id);
    setUserIds(userIds);
  }, [users]);

  const onChange = (event) => {
    data[event.target.name] = event.target.value.trim();
    setData(data);
  };

  const onChangeContent = (event) => {
    data['content'] = event.editor.getData();
    setData(data);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (
      data &&
      data['content'] &&
      (data['content'].indexOf('%%LOGIN_') > -1 ||
        data['content'].indexOf('%NICKNAME%') > -1 ||
        data['content'].indexOf('%URL_LP%') > -1 ||
        data['content'].indexOf('%MAIL_ADDRESS%') > -1 ||
        data['content'].indexOf('%PHONE_NUMBER%') > -1 ||
        data['content'].indexOf('%URL_CONTACT%') > -1 ||
        data['content'].indexOf('%PROF_POINT%') > -1 ||
        data['content'].includes('%%PROFILE_SQLID') ||
        data['content'].indexOf('%SITE_NAME%') > -1)
    ) {
      return sendMultiUrl(data['content']);
    } else {
      data['content'] = await replaceTag(data['content'], false, null);
    }

    if (users.length > 0) {
      data['users'] = users;
    }

    if (!userIds || (userIds && !userIds.length)) {
      setLoading(false);
      toast.error('受信者数が０人のため、EMAIL送信できません。');
      return;
    }

    if (String(timerMail) && +timerMail === DISPLAY_OPTIONS.OFF) {
      await sendEmailListUser({ data, userIds })
        .then((response) => {
          onClose();
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        })
        .catch((e) => {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
          onClose();
        });
    } else {
      await setTimeMail({
        sendTime: timeSend,
        fromAddress: data['fromAddress'],
        userIds: userIds,
        type: TIMER_SENDING_TYPE.FROM_USER_SEARCH,
        ...data,
      })
        .then((response) => {
          onClose();
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        })
        .catch((e) => {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
          onClose();
        });
    }
  };

  const handleConfirm = () => {
    if (!data.title) {
      return toast.error('タイトルしてください!');
    }
    if (!data.content) {
      return toast.error('本文してください!');
    }
    const startDay = new Date(moment().startOf('day')).getTime();
    const checkTime = timeSend || startDay;

    if (
      String(timerMail) &&
      +timerMail === DISPLAY_OPTIONS.ON &&
      checkTime &&
      checkTime < new Date().getTime()
    ) {
      return toast.error('予約時間が過去に設定されています');
    }
    replaceUrlPreview(data.content);
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  let minute = [];
  for (let index = 0; index < 60; index++) {
    if (index < 10) {
      minute.push('0' + index);
    } else {
      minute.push(index);
    }
  }

  const handleDate = (date) => {
    setSelectedDate(date);
    date = moment(date).format('YYYY-MM-DD');
    setDateSend(date);
    let sendTime = new Date(`${date} ${hourSend}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleMinus = (e) => {
    let value = e.target.value;
    setMinusSend(value);
    let sendTime = new Date(`${dateSend} ${hourSend}:${value}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourSend(value);
    let sendTime = new Date(`${dateSend} ${value}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleFromMailAddress = (e, key) => {
    data[key] = e.target.value;
    data['fromAddress'] =
      e.target[e.target.selectedIndex].getAttribute('data-key');
    setData(data);
  };

  const replaceProfileSQLId = async (
    content,
    preview,
    phoneNumber,
    password,
    needOtp,
  ) => {
    if (content.includes('%%PROFILE_SQLID')) {
      const item = conversionTag.find(
        (t) => t.tag === '%%PROFILE_SQLID_UUID%%',
      );
      if (item) {
        const userSendId = (
          content.split('%%PROFILE_SQLID_')[1] + ''
        ).substring(0, 36);
        const IsGuid =
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
            userSendId,
          );
        if (IsGuid) {
          let user = null;
          try {
            const response = await getUser(userSendId);
            const { data: userData } = response;
            user = userData.data;
            const myRegex = new RegExp(`%%PROFILE_SQLID_${userSendId}%%`, 'g');
            const path = `-user/${userSendId.replace(/-/g, '*')}`;
            const params = `${phoneNumber}-${password || ''}${path}`;
            const url = Util.buildLink('/autologin/?id=' + btoa(params));
            const userInfo = `◇${user.nickName}　${Util.calcAge(
              Util.timestampToDate(user.birthday, 'YYYY-MM-DD'),
            )}歳　${user.gender === GENDER.MALE ? '男性' : '女性'}　${
              user?.states?.label || ''
            }`;
            content = content.replace(
              myRegex,
              `<p>${userInfo}</p>${
                preview
                  ? item.previewMail || item.contentMail
                  : item.contentMail
              }`.replace(/#URL#/g, url),
            );
          } catch (e) {
            content = content.replace(
              new RegExp(`%%PROFILE_SQLID_${userSendId}`, 'g'),
              '<span style="color: red">PROFILE_SQLID_ERROR_TAG</span>',
            );
          }
        } else {
          const text = userSendId.includes('%%')
            ? userSendId.split('%%')[0]
            : userSendId;
          content = content.replace(
            new RegExp(`%%PROFILE_SQLID_${text}%%`, 'g'),
            '<span style="color: red">PROFILE_SQLID_ERROR_TAG</span>',
          );
        }
        if (content.includes('%%PROFILE_SQLID')) {
          return replaceProfileSQLId(
            content,
            preview,
            phoneNumber,
            password,
            needOtp,
          );
        }

        return content;
      }
    }
    return content;
  };

  const sendMultiUrl = (content) => {
    if (users) {
      users.map(async (t) => {
        getUser(t.id).then(async (res) => {
          const { data: userDetail } = res;
          if (userDetail.data.password) {
            const user = userDetail.data;
            const mailContent = await replaceTag(content, false, {
              phoneNumber: user.phoneNumber,
              password: user.password,
              nickName: user.nickName,
              depositpoint: user.depositpoint,
              needOtp: user.needOtp,
            });
            data['content'] = mailContent;
            const sendData = { ...data };
            if (String(timerMail) && +timerMail === DISPLAY_OPTIONS.OFF) {
              await sendEmailListUser({ userIds: [t.id], data });
            } else {
              await setTimeMail({
                sendTime: timeSend,
                fromAddress: sendData['fromAddress'],
                userIds: [t.id],
                type: TIMER_SENDING_TYPE.FROM_USER_SEARCH,
                ...sendData,
              });
            }
          }
        });
      });
      toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      onClose();
    }
  };

  const replaceTag = async (content, preview, userData) => {
    if (!userData) {
      return content;
    }
    const { phoneNumber, password, nickName, depositpoint, needOtp } = userData;
    if (content.includes('%%PROFILE_SQLID')) {
      const contentProfile = await replaceProfileSQLId(
        content,
        preview,
        phoneNumber,
        password,
        needOtp,
      );
      if (contentProfile) content = contentProfile;
    }
    for (const item of conversionTag) {
      const regex = new RegExp(item.tag, 'g');
      if (
        [
          '%%LOGIN_HOME%%',
          '%%LOGIN_BUY_POINT%%',
          '%%LOGIN_MYPAGE%%',
          '%%LOGIN_INFO_1%%',
          '%%LOGIN_INFO_2%%',
          '%%LOGIN_INFO_3%%',
          '%%LOGIN_INFO_4%%',
          '%%LOGIN_INFO_5%%',
          '%%LOGIN_INFO_6%%',
          '%%LOGIN_INFO_7%%',
          '%%LOGIN_INFO_8%%',
          '%%LOGIN_MYPROFILE%%',
          '%%LOGIN_CHAT_ROOM%%',
        ].includes(item.tag) &&
        content.indexOf(item.tag) > -1
      ) {
        let path = '';
        if (item.tag === '%%LOGIN_BUY_POINT%%') {
          path = '-point';
        }
        if (item.tag === '%%LOGIN_MYPAGE%%') {
          path = '-mypagemale';
        }
        if (item.tag === '%%LOGIN_INFO_1%%') {
          path = '-info/info1';
        }
        if (item.tag === '%%LOGIN_INFO_2%%') {
          path = '-info/info2';
        }
        if (item.tag === '%%LOGIN_INFO_3%%') {
          path = '-info/info3';
        }
        if (item.tag === '%%LOGIN_INFO_4%%') {
          path = '-info/info4';
        }
        if (item.tag === '%%LOGIN_INFO_5%%') {
          path = '-info/info5';
        }
        if (item.tag === '%%LOGIN_INFO_6%%') {
          path = '-info/info6';
        }
        if (item.tag === '%%LOGIN_INFO_7%%') {
          path = '-info/info7';
        }
        if (item.tag === '%%LOGIN_INFO_8%%') {
          path = '-info/info8';
        }
        if (item.tag === '%%LOGIN_MYPROFILE%%') {
          path = `-myprofile`;
        }
        if (item.tag === '%%LOGIN_CHAT_ROOM%%') {
          path = `-chat`;
        }
        const params = `${phoneNumber}-${password || ''}${path}`;
        const url = Util.buildLink('/autologin/?id=' + btoa(params));
        content = content.replace(
          regex,
          (preview
            ? item.previewMail || item.contentMail
            : item.contentMail
          ).replace(/#URL#/g, url),
        );
      } else if (item.tag === '%NICKNAME%' && content.indexOf(item.tag) > -1) {
        content = content.replace(
          regex,
          preview ? item.previewMail : `${nickName}`,
        );
      } else if (
        item.tag === '%PROF_POINT%' &&
        content.indexOf(item.tag) > -1
      ) {
        content = content.replace(
          regex,
          preview ? item.previewMail : `${depositpoint}`,
        );
      } else if (item.tag === '%SITE_NAME%' && content.indexOf(item.tag) > -1) {
        const description = (envInfoTag ?? []).find(
          (t) => t.code === CODE_MAP_DEFINED_API.SITE_NAME,
        )?.content;
        content = content.replace(
          regex,
          preview ? item.previewMail : `${description}`,
        );
      } else if (item.tag === '%URL_LP%' && content.indexOf(item.tag) > -1) {
        const url = Util.buildLink('/lp');
        const txtLp = preview
          ? item.previewMail || item.contentMail
          : item.contentMail;
        if (txtLp) {
          content = content.replace(regex, txtLp.replace(/#URL#/g, url));
        }
      } else if (
        item.tag === '%MAIL_ADDRESS%' &&
        content.indexOf(item.tag) > -1
      ) {
        const description = (envInfoTag ?? []).find(
          (t) => t.code === CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS,
        )?.content;
        content = content.replace(
          regex,
          preview ? item.previewMail : `${description}`,
        );
      } else if (
        item.tag === '%PHONE_NUMBER%' &&
        content.indexOf(item.tag) > -1
      ) {
        const description = (envInfoTag ?? []).find(
          (t) => t.code === CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER,
        )?.content;
        content = content.replace(
          regex,
          preview ? item.previewMail : `${description}`,
        );
      } else if (
        item.tag === '%URL_CONTACT%' &&
        content.indexOf(item.tag) > -1
      ) {
        const url = Util.buildLink(CONVERSION_TAG_URL.URL_CONTACT);
        const txtContact = preview
          ? item.previewMail || item.contentMail
          : item.contentMail;
        if (txtContact) {
          content = content.replace(regex, txtContact.replace(/#URL#/g, url));
        }
      } else {
        content = content.replace(
          regex,
          preview ? item.previewMail || item.contentMail : item.contentMail,
        );
      }
    }

    return content;
  };

  const replaceUrlPreview = (content) => {
    getUser(users[0].id).then(async (res) => {
      const { data: userDetail } = res;
      const user = userDetail.data;
      const replaceContent = await replaceTag(content, true, {
        phoneNumber: user.phoneNumber,
        password: user.password,
        nickName: user.nickName,
        depositpoint: user.depositpoint,
        needOtp: user.needOtp,
      });
      setContentHtml(replaceContent);
      setConFirmVisible(true);
    });
  };

  const handlerTimerMail = (value) => {
    setTimerMail(`${value}`);
  };

  return (
    <div>
      <LoadingBar show={loading} />
      {confirmVisible && (
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={confirmVisible}
          onClose={onCloseConfirm}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          />
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onCloseConfirm}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '0.6rem 0 1rem 0' }}>
              {/*実行してよろしいですか？*/}
              <Card body className="mb-4">
                <Row>
                  <Col
                    style={style}
                    xs={6}
                    className={'d-line-content d-flex d-justify-space-between'}
                  >
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <p style={{ paddingLeft: 3 }}>日時</p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'end',
                      }}
                      className={'mr-1'}
                    >
                      <p>
                        {String(timerMail) &&
                        +timerMail === DISPLAY_OPTIONS.ON &&
                        timeSend
                          ? moment(timeSend).format('YYYY/MM/DD HH:mm')
                          : dateSend}
                      </p>
                    </div>
                  </Col>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'end',
                      }}
                      className={'mr-1'}
                    >
                      <span
                        className={`${
                          String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON
                            ? 'd-red-color'
                            : 'd-blue-color'
                        }`}
                      >
                        {convertDisplayOptionsText(timerMail)}
                      </span>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col
                    style={style}
                    xs={6}
                    className={'d-line-content d-flex d-justify-space-between'}
                  >
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <p style={{ paddingLeft: 3 }}>送信元メールアドレス</p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'end',
                      }}
                      className={'mr-1'}
                    >
                      {data?.from_mail_address}
                    </div>
                  </Col>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      &nbsp;
                    </div>
                  </Col>
                </Row>

                <Row className={'d-line-col'}>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      &nbsp;
                    </div>
                  </Col>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      &nbsp;
                    </div>
                  </Col>
                </Row>
              </Card>
              <Card body className="mb-4">
                <Row>
                  <Label style={style} xs={3}>
                    <p style={{ paddingLeft: 3 }}>タイトル</p>
                  </Label>
                  <Label style={style} xs={9}>
                    {data?.title}
                  </Label>
                </Row>
              </Card>
              <Card body className="mb-4">
                <Row>
                  <Label style={style} xs={3}>
                    <p style={{ paddingLeft: 3 }}>本文</p>
                  </Label>
                  <Label style={style} xs={9}>
                    <div
                      dangerouslySetInnerHTML={{ __html: contentHtml }}
                    ></div>
                  </Label>
                </Row>
              </Card>
            </div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={onCloseConfirm}
                  color="primary"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>EMAIL送信</p>
            <div style={{ fontSize: '1rem' }} className={'wrapper-info-title'}>
              受信者数 <span>{totalRow}人</span>
            </div>
          </div>
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>

        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{
              __html: TEXT_HTML_MANAGEMENT_USER_SEARCH_EMAIL_LINK_MODAL,
            }}
          ></div>
        </div>
        <DialogContent>
          <Card style={{ marginBottom: 10 }}>
            <Row style={{ padding: 20 }}>
              <Col xs={12}>
                <div style={{ margin: '16px 0 8px 0' }}>
                  <span style={{ color: 'red' }}>*</span>タイトル
                </div>
                <input
                  style={{
                    width: '100%',
                    marginBottom: '20px',
                    border: '1px solid rgb(118, 118, 118)',
                  }}
                  name="title"
                  onChange={onChange}
                />
                <div style={{ margin: '16px 0 8px 0' }}>
                  <span style={{ color: 'red' }}>*</span>本文
                </div>
                <CustomEditor
                  name="content"
                  rows="3"
                  data=""
                  onChange={onChangeContent}
                  style={{ width: '100%', marginBottom: '20px' }}
                  maxLength={500}
                />
                <div style={{ margin: '16px 0 8px 0' }}>
                  <span style={{ color: 'red' }}>*</span>FROM MAIL ADDRESS
                </div>
                <Row>
                  <Col className="datePicker" xs={12}>
                    <select
                      onChange={(e) =>
                        handleFromMailAddress(e, 'from_mail_address')
                      }
                      style={{ width: '100%' }}
                    >
                      {fromMails.map((t) => (
                        <option data-key={t.key} value={t.value}>
                          {t.value}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>

                <div className="mt-3 mb-1 timer-container">
                  <span>タイマー日時</span>
                </div>
                <div className="d-flex align-items-center">
                  <div className="datePicker">
                    <DatePicker
                      selected={selectedDate}
                      className="date"
                      onChange={(e) => handleDate(e)}
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                      popperPlacement="top-start"
                      disabled={
                        !(
                          String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON
                        )
                      }
                    />
                    <select
                      defaultChecked={hourSend}
                      onChange={(e) => handleHour(e, 'start_hour')}
                      className="hour"
                      disabled={
                        !(
                          String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON
                        )
                      }
                    >
                      {hour.map((value) => (
                        <option
                          selected={value.toString() === hourSend}
                          key={value}
                          value={value}
                        >
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => handleMinus(e, 'start_minus')}
                      className="minus"
                      disabled={
                        !(
                          String(timerMail) && +timerMail === DISPLAY_OPTIONS.ON
                        )
                      }
                    >
                      {minute.map((value) => (
                        <option
                          selected={value.toString() === minusSend}
                          key={value}
                          value={value}
                        >
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="display-setting-timer d-un-width">
                    <RadioGroup
                      name="displaySetting"
                      defaultValue={timerMail}
                      options={displayOptionsOptionsRequired()}
                      onChange={(e) => handlerTimerMail(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card>

          <div style={{ textAlign: 'right', marginBottom: 2 }}>
            {data ? (
              <Button
                onClick={handleConfirm}
                color="primary"
                className="btn-custom btn-blue"
              >
                送信
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'lightgray',
                  color: 'white',
                  cursor: 'no-drop',
                  padding: '4px 36px',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                送信
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SendEmailModal;
