import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import Util from '../../../Util/Util';
import get from 'lodash/get';
import RemoveModal from './RemoveModal';
import moment from 'moment';
import { updateTimerMail } from '../../../Api/timerAPI';
import { toast } from 'react-toastify';
import CustomEditor from '../../../Components/CustomEditor';
import { TEXT_HTML_MANAGEMENT_TIMER_STANDBY_MAIL_LINK_MODAL } from '../../../Common/modal_text_info';
import {
  convertDisplayOptionsText,
  convertTimerSendingTypeText,
} from '../../../Util/utils';
import { DISPLAY_OPTIONS } from '../../../Common/constant';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import DateTimePicker from '../shared/DateTimePicker';
import _ from 'lodash';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function TimerMailDetailModal({
  onClose,
  timerMail,
  reload,
  fromMails,
  fromMailAddress,
}) {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeCustomDateTime = (name, value) => {
    const updatedData = _.set(data, name, value);
    setData({ ...updatedData });
  };

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemove = () => {
    setConfirmVisible(true);
  };

  const handleUpdate = async () => {
    await updateTimerMail({ ...data, id: timerMail.id }).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_EDIT);
      }
    });
  };

  const handleFromMailAddress = (e) => {
    data['fromAddress'] =
      e.target[e.target.selectedIndex].getAttribute('data-key');
    setData(data);
  };

  const onChangeContent = (event) => {
    data['content'] = event.editor.getData();
    setData(data);
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <RemoveModal
          visible={confirmVisible}
          onClose={handleClose}
          timerMailId={timerMail?.id}
          reload={reload}
          onCloseDetail={onClose}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={!!timerMail}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          予約一覧メール-詳細
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{
              __html: TEXT_HTML_MANAGEMENT_TIMER_STANDBY_MAIL_LINK_MODAL,
            }}
          ></div>
        </div>

        <DialogContent>
          <Row>
            <Col xs={12}>
              {!isEditing ? (
                <Button
                  variant="contained"
                  className="btn-custom btn-blue float-right"
                  onClick={() => setIsEditing(true)}
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  編集
                </Button>
              ) : (
                <div className="float-right" style={{ marginBottom: 10 }}>
                  <Button
                    className="btn-custom btn-red"
                    variant="contained"
                    onClick={() => setIsEditing(false)}
                  >
                    閉じる
                  </Button>
                  <Button
                    className="btn-custom btn-blue"
                    variant="contained"
                    onClick={handleUpdate}
                    style={{ marginRight: 10 }}
                  >
                    更新する
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {!isEditing ? (
                <Card
                  className="card card-body"
                  style={{ marginBottom: '1rem' }}
                >
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>日時</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>
                            {moment(Number(get(timerMail, 'sendTime'))).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )}
                            &nbsp;{' '}
                            {convertDisplayOptionsText(DISPLAY_OPTIONS.ON)}
                          </p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>送信元メールアドレス</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{fromMailAddress}</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p style={{ opacity: 0 }}>1</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p style={{ opacity: 0 }}>1</p>
                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>送信種別</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{convertTimerSendingTypeText(timerMail?.type)}</p>
                          <hr style={stylehrright} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p style={{ opacity: 0 }}>1</p>
                          <hr style={stylehrleft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p style={{ opacity: 0 }}>1</p>

                          <hr style={stylehrright} />
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card className="card card-body mb-2">
                  <DateTimePicker
                    name="sendTime"
                    title="日時"
                    onChange={onChangeCustomDateTime}
                    timestamp={timerMail.sendTime}
                  />
                  <Row className="mt-2">
                    <Col xs={12}>
                      <FormLabel component="legend">
                        <span style={{ color: 'red' }}>*</span>
                        送信元メールアドレス
                      </FormLabel>
                    </Col>
                    <Col xs={12}>
                      <select
                        onChange={handleFromMailAddress}
                        style={{ width: '100%' }}
                      >
                        {fromMails.map((t) => (
                          <option
                            selected={timerMail.fromAddress === t.key}
                            data-key={t.key}
                            value={t.value}
                          >
                            {t.value}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </Card>
              )}

              <Card className="card card-body" style={{ marginBottom: '1rem' }}>
                {!isEditing ? (
                  <Row>
                    <Label xs={2}>タイトル</Label>
                    <Label xs={10}>{timerMail.title}</Label>
                  </Row>
                ) : (
                  <Row>
                    <Col xs={12}>
                      <FormLabel component="legend">タイトル</FormLabel>
                      <textarea
                        rows="3"
                        defaultValue={timerMail.title}
                        style={{ width: '100%', padding: 10 }}
                        name="title"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                )}
              </Card>
              <Card className="card card-body" style={{ marginBottom: '1rem' }}>
                {!isEditing ? (
                  <Row>
                    <Label xs={2}>本文</Label>
                    <Label xs={10}>
                      <div
                        dangerouslySetInnerHTML={{ __html: timerMail.content }}
                      ></div>
                    </Label>
                  </Row>
                ) : (
                  <Row>
                    <Col xs={12}>
                      <FormLabel component="legend">本文</FormLabel>
                      <CustomEditor
                        name="content"
                        rows="3"
                        initData={timerMail.content}
                        data=""
                        onChange={onChangeContent}
                        style={{ width: '100%', marginBottom: '20px' }}
                        maxLength={500}
                      />
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
          </Row>
          <Button
            variant="contained"
            className="btn-custom btn-red float-right"
            onClick={handleRemove}
            style={{ marginBottom: 2 }}
          >
            削除
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
