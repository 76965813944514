import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Col, FormGroup, Row } from 'reactstrap';

import { getOperators } from '../../Api/operatorAPI';
import { getAgents } from '../../Api/agentAPI';
import { usagePointType } from '../../Common/constant_text';
import { COLUMN_DEFINED_SEARCH_BOX } from '../../Common/constant';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DEFAULT_FILTER_STATE = {
  receiver_phone: '',
  sender_phone: '',
  receiver_nickname: '',
  sender_nickname: '',
  operatorid: '',
  agentid: '',
  created_date_lower: '',
  created_date_upper: '',
  salaryLogType: [],
};

function SearchBox(props) {
  const { labelDb } = props;
  const classes = useStyles();

  const [filter, setFilter] = useState(DEFAULT_FILTER_STATE);
  const [operators, setOperators] = useState([]);
  const [agents, setAgents] = useState([]);

  React.useEffect(() => {
    getOperators({ pageIndex: 1, pageSize: 1000 }).then((res) => {
      setOperators(res.data.data);
    });
    getAgents({ pageIndex: 1, pageSize: 1000 }).then((res) => {
      setAgents(res.data.data);
    });
  }, []);

  const onChange = (event) => {
    event.persist();
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value || undefined,
    }));
  };

  const handleClear = () => {
    setFilter(DEFAULT_FILTER_STATE);
  };

  const handleSearch = () => {
    try {
      let salaryLogType = filter.salaryLogType
        .map((item) => usagePointType.find((item2) => item2.id === item).id)
        .join(',');
      return props.onSubmit({ ...filter, salaryLogType });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <React.Fragment>
      {/* <h4 className="page-title">チャット画像</h4> */}
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1}>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  value={filter.receiver_phone}
                  id="standard-required"
                  label="電話番号-ユーザー"
                  type="text"
                  name="receiver_phone"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  value={filter.sender_phone}
                  id="standard-required"
                  label="電話番号-キャラ"
                  type="text"
                  name="sender_phone"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  value={filter.receiver_nickname}
                  id="standard-required1"
                  label={`${labelDb?.label_nickName?.content}-ユーザー`}
                  type="text"
                  name="receiver_nickname"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  value={filter.sender_nickname}
                  id="standard-required1"
                  label={`${labelDb?.label_nickName?.content}-キャラ`}
                  type="text"
                  name="sender_nickname"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <FormControl className="w-100">
                  <InputLabel id="operatorid">オペレーターNumber</InputLabel>
                  <Select
                    id="operatorid"
                    name="operatorid"
                    onChange={onChange}
                    className="w-100"
                    value={filter.operatorid}
                  >
                    <MenuItem value="">---</MenuItem>
                    {operators.map((v) => {
                      return (
                        <MenuItem value={v.operatorNumber} key={v.id}>
                          {v.operatorNumber}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col xs={12}>
                <FormControl className="w-100">
                  <InputLabel id="agentid">会社Number</InputLabel>
                  <Select
                    id="agentid"
                    name="agentid"
                    onChange={onChange}
                    className="w-100"
                    value={filter.agentid}
                  >
                    <MenuItem value={''}>---</MenuItem>
                    {agents.map((v) => {
                      return (
                        <MenuItem value={v.agentNumber} key={v.id}>
                          {v.agentNumber}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            </FormGroup>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2}>
            <FormGroup row>
              <Col xs={5}>
                <TextField
                  value={filter.created_date_lower}
                  id="date"
                  label="登録日"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="created_date_lower"
                  onChange={onChange}
                />
              </Col>
              <Col xs={2} className="flex-end d-col-2-symbol">
                〜
              </Col>
              <Col xs={5}>
                <TextField
                  value={filter.created_date_upper}
                  id="date"
                  label="登録日"
                  className="w-100"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="created_date_upper"
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3}>
            <Col xs={12}>
              <FormGroup row>
                <Col xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink>種類</InputLabel>
                    <Select
                      multiple={true}
                      onChange={onChange}
                      name="salaryLogType"
                      value={filter.salaryLogType}
                      input={<Input />}
                      renderValue={(selected) => {
                        return selected
                          .sort()
                          .map(
                            (item) =>
                              usagePointType.find(
                                (option) => option.id === item,
                              )?.value,
                          )
                          .join(', ');
                      }}
                      style={{ width: '100%' }}
                      className={classes.selectEmpty}
                    >
                      {usagePointType.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          <Checkbox
                            checked={
                              filter.salaryLogType?.indexOf(option.id) > -1
                            }
                          />
                          <ListItemText primary={`${option.value}`} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </FormGroup>
            </Col>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchBox;
