import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function ConfirmSetRepliedAllMessagesModal({
  strClass,
  onClose,
  visible,
  handleSubmit,
  title,
  content,
  contentSticky,
}) {
  return (
    <div className={strClass}>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom d-title-modal-delete'}
        >
          <div
            className="w-100"
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p className={'d-title mb-0'}>{title}</p>
            <Button onClick={onClose}>
              <CloseIcon color="primary" />
            </Button>
          </div>
        </DialogTitle>
        {contentSticky && (
          <div className={'d-title-info-box'}>
            <div
              className={'text mb-0'}
              dangerouslySetInnerHTML={{ __html: contentSticky }}
            />
          </div>
        )}

        <DialogContent>
          <div
            style={{ margin: '0 0 1rem 0', whiteSpace: 'pre' }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div>
            <center style={{ margin: 15 }}>
              <Button
                onClick={onClose}
                variant="contained"
                className="btn-custom btn-red"
              >
                いいえ
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="btn-custom btn-blue ml-2"
              >
                はい
              </Button>
            </center>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
