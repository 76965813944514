import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import { loadLogNoti } from '../../../Redux/Actions/logSendNoti';
import LogSendDetailModal from './LogSendDetailModal';
import LogSendList from './LogSendList';
import { convertEnvInfo } from '../../../Util/utils';

const pageSize = 50;

function Index() {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.logSendNoti.loading);
  const logSendNoti = useSelector((state) => state.logSendNoti.items);
  const totalRow = useSelector((state) => state.logSendNoti.totalRow);
  const [logSendDetail, setLogSendDetail] = useState(null);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    sort: { 'LogSendNoti.createdDate': 'DESC' },
  });

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (
      filter.sort[sortByKey] === undefined ||
      filter.sort[sortByKey] === 'DESC'
    ) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const handleClickDetail = (logSend) => {
    setLogSendDetail(logSend);
  };

  const handleClose = () => {
    setLogSendDetail(null);
  };

  const reload = () => {
    dispatch(loadLogNoti({ pageIndex: 1, pageSize, ...filter }));
  };

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadLogNoti({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadLogNoti({ ...filter, pageIndex, pageSize }));
  };

  const onSearch = (searchData) => {
    setFilter({ ...searchData });
  };

  const onClear = () => {
    setFilter({ sort: { 'LogSendNoti.createdDate': 'DESC' } });
  };

  return (
    <div>
      <LoadingBar show={loading} />
      {logSendDetail && (
        <LogSendDetailModal
          onClose={handleClose}
          logSend={logSendDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}
      <LogSendList
        loading={loading}
        logSendNoti={logSendNoti}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
        onSearch={onSearch}
        onClear={onClear}
        labelDb={labelDb}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
