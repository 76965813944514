import { Button, Card, createMuiTheme, Dialog, DialogContent, DialogTitle, MuiThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Label, Row } from 'reactstrap';
import get from 'lodash/get';
import RemovePointLogModal from './RemovePointLogModal';
import moment from 'moment';
import Util from '../../../Util/Util';
import { TEXT_HTML_MANAGEMENT_LOG_POINT_USAGE_LINK_MODAL } from '../../../Common/modal_text_info';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {},
      paddingCheckbox: {
        padding: '5px 20px 5px 4px',
        width: 0,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const styleHrLeft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const styleHrRight = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function Index({ onClose, item, reload, labelDb }) {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemove = () => {
    setConfirmVisible(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <RemovePointLogModal
          visible={confirmVisible}
          onClose={handleClose}
          pointUsageId={item?.id}
          reload={reload}
          onCloseDetail={onClose}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={!!item}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>ポイント消費ログ-詳細</p>
          </div>
        </DialogTitle>
        <Button
          style={{ position: 'absolute', top: 10, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>
        <div className={'d-title-info-box'}>
          <div className={'text mb-0'}
               dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_LOG_POINT_USAGE_LINK_MODAL }}></div>
        </div>

        <DialogContent>
          <MuiThemeProvider theme={theme}>
            <Row>
              <Col xs={12}>
                <Card
                  className="card card-body"
                  style={{ marginBottom: '1rem' }}
                >
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>日時</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>
                            {moment(Number(get(item, 'createdDate'))).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )}
                          </p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>{labelDb?.label_nickName?.content}（消費者）</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{item?.user?.nickName}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>電話番号（消費者）</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{item?.user?.phoneNumber}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>オペNum</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{item?.target?.operator?.operatorNumber}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>会社Num</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{item?.target?.agent?.agentNumber}</p>
                          <hr style={styleHrRight} />
                        </Label>
                      </Row>
                    </Col>

                    <Col xs={6}>
                      <Row>
                        <Label xs={6} style={style}>
                          <p>消費ポイント</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.separator(item?.point)}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>種類</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.convertPointUsageTypeToText(item?.type)}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>消費前ポイント</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.separator(item?.pointBefore)}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>消費後ポイント</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.separator(item?.pointAfter)}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>端末</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{Util.typeConnectionToText(item?.device)}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>{labelDb?.label_nickName?.content}（お相手）</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{item?.target?.nickName}</p>
                          <hr style={styleHrRight} />
                        </Label>

                        <Label xs={6} style={style}>
                          <p>電話番号（お相手）</p>
                          <hr style={styleHrLeft} />
                        </Label>
                        <Label xs={6} style={style}>
                          <p>{item?.target?.phoneNumber}</p>
                          <hr style={styleHrRight} />
                        </Label>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Button
              variant="contained"
              className="btn-custom btn-red float-right"
              onClick={handleRemove}
              style={{ marginBottom: 2 }}
            >
              削除
            </Button>
          </MuiThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
