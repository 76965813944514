import React, { useState } from 'react';
import {
  Button,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  TextField,
  FormLabel,
} from '@material-ui/core';
import RadioGroup from '../../../Components/RadioGroup';
import { Col, FormGroup, Row } from 'reactstrap';
import { COLUMN_DEFINED_SEARCH_BOX } from '../../../Common/constant';
import { LOG_SEND_MAIL_TYPE } from 'Common/constant_text';

const theme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
  },
});

const DEFAULT_FILTER_STATE = {
  phone: '',
  nickName: '',
  fromDate: '',
  toDate: '',
  type: undefined,
};
function SearchBox(props) {
  const { labelDb } = props;
  const [filter, setFilter] = useState(DEFAULT_FILTER_STATE);

  const onChange = (event) => {
    event.persist();
    setFilter((filter) => ({
      ...filter,
      [event.target.name]: event.target.value || undefined,
    }));
  };

  const handleSearch = () => {
    try {
      return props.onSubmit(filter);
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    setFilter(DEFAULT_FILTER_STATE);
  };

  return (
    <React.Fragment>
      {/* <h4 className="page-title">入金管理-検索条件入力</h4> */}
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1}>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  value={filter.phone}
                  label="電話番号"
                  type="text"
                  name="phone"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <TextField
                  value={filter.nickName}
                  id="standard-required"
                  label={labelDb?.label_nickName?.content}
                  type="text"
                  name="nickName"
                  onChange={onChange}
                  className="w-100"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2}>
            <MuiThemeProvider theme={theme}>
              <FormGroup row>
                <Col xs={5}>
                  <TextField
                    value={filter.fromDate}
                    id="date"
                    label="登録日"
                    className="w-100"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="fromDate"
                    onChange={onChange}
                  />
                </Col>
                <Col xs={2} className="flex-end d-col-2-symbol">
                  〜
                </Col>
                <Col xs={5}>
                  <TextField
                    value={filter.toDate}
                    id="date"
                    label="登録日"
                    className="w-100"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="toDate"
                    onChange={onChange}
                  />
                </Col>
              </FormGroup>
            </MuiThemeProvider>
          </Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3}>
            <Col xs={12} className="d-item-form-wrapper">
              <FormLabel component="legend">タイプ</FormLabel>
              <RadioGroup
                controlled
                name="type"
                defaultValue={filter.type}
                options={[
                  { value: undefined, label: '全て' },
                  ...LOG_SEND_MAIL_TYPE,
                ]}
                onChange={onChange}
                classCustom="d-cus-item"
              />
            </Col>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <Button
            className="btn-red"
            variant="contained"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            クリア
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchBox;
